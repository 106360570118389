import { Translation } from "./translations";

export function commonTranslation() {
    const translations: Translation[] = [
        {
            key: "common_toast_save_success",
            value: "Save was a success",
        },
        {
            key: "common_toast_save_detail_success",
            value: "Information was saved",
        },
        {
            key: "common_cancel",
            value: "Cancel",
        },
        {
            key: "common_delete",
            value: "Delete",
        },
        {
            key: "common_save",
            value: "Save",
        },
        {
            key: "common_savechanges",
            value: "Save changes",
        },
        {
            key: "common_create",
            value: "Create",
        },
        {
            key: "common_required",
            value: "This is required",
        },
        {
            key: "common_ok",
            value: "Ok",
        },
        {
            key: "common_close",
            value: "Close",
        },
        {
            key: "common_searchplaceholder",
            value: "Search..",
        },
        {
            key: "common_password",
            value: "Password",
        },
        {
            key: "common_LogIn",
            value: "Login",
        },
        {
            key: "common_LogOut",
            value: "Logout",
        },
        {
            key: "common_image",
            value: "Image",
        },
        {
            key: "common_support",
            value: "Support",
        },
        {
            key: "common_account_error_WrongLogin",
            value: "Incorrect username or password",
        },
        {
            key: "common_account_usersettings",
            value: "User settings",
        },
        {
            key: "common_next",
            value: "Next",
        },
        {
            key: "common_account_confirm_email",
            value: "Confirm email",
        },
        {
            key: "common_account_unknown_email",
            value: "Your mail are unknown in our system, please confirm that is correct",
        },
        {
            key: "common_email",
            value: "Email",
        },
        {
            key: "common_phone",
            value: "Phone number",
        },
        {
            key: "common_mobilenumber",
            value: "Mobilenumber",
        },
        {
            key: "common_primarylanguage",
            value: "Primary language",
        },

        {
            key: "common_name",
            value: "Name",
        },
        {
            key: "common_add",
            value: "add",
        },
        {
            key: "common_ZoomInOnMap",
            value: "Zoom on workarea",
        },
        {
            key: "common_state",
            value: "State",
        },
        {
            key: "common_createdby",
            value: "Created by",
        },
        {
            key: "common_createddate",
            value: "Created date",
        },
        {
            key: "common_totalcost",
            value: "Total cost",
        },
        {
            key: "common_copy",
            value: "Copy",
        },
        {
            key: "common_print",
            value: "Print",
        },
        {
            key: "common_printExcelfile",
            value: "Download Excel file",
        },
        {
            key: "common_validation_required",
            value: "This field is required!",
        },
        {
            key: "common_validation_min3charachters",
            value: "Enter a minimum of 3 characters!",
        },

        {
            key: "common_validation_numberOnly",
            value: "This field most only contain numbers!",
        },
        {
            key: "common_fieldname",
            value: "Fieldname",
        },
        {
            key: "common_newname",
            value: "New name",
        },
        {
            key: "common_Resetpassword",
            value: "Reset password",
        },
        {
            key: "common_gobackto_login",
            value: "Return to login page",
        },
    ];
    return translations;
}
