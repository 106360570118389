import { DashboardCard, DashboardCardBody } from "../types/mixedtypes";
import reqwest from "shared/utils/request";
import { getActions } from "../actionregistry";
import { Result } from "../presenter";
import { runInAction } from "mobx";

export async function loadDashBoardCards(): Promise<DashboardCard[]> {
    try {
        const result = await reqwest.ajax<DashboardCard[]>({
            url: config.SERVICE_URL + "/Admin/Dashboard/ClientCards",
            type: "json",
            // @ts-ignore
            withCredentials: true,
            crossOrigin: true,
            method: "GET",
        });
        return result;
    } catch (response: any) {
        if (response.status === 401) {
            const result: Result = await getActions()!.showLoginDialog();
            runInAction(() => {
                if (result == "ok") {
                    return loadDashBoardCards();
                }
            });
        }
    }
    return [];
}
export async function clientCardUpdate(card: DashboardCard): Promise<DashboardCard> {
    try {
        const result = await reqwest.ajax<DashboardCard>({
            url: config.SERVICE_URL + "/Admin/Dashboard/UpdateCard",
            type: "json",
            // @ts-ignore
            withCredentials: true,
            crossOrigin: true,
            method: "PUT",
            data: JSON.stringify(card),
            contentType: "application/json",
        });
        return result;
    } catch (response: any) {
        if (response.status === 401) {
            const result: Result = await getActions()!.showLoginDialog();
            runInAction(() => {
                if (result == "ok") {
                    return clientCardUpdate(card);
                }
            });
        }
        return clientCardUpdate(card);
    }
}
export async function clientCardCreate(card: DashboardCardBody): Promise<DashboardCard> {
    try {
        const result = await reqwest.ajax<DashboardCard>({
            url: config.SERVICE_URL + "/Admin/Dashboard/CreateCard",
            type: "json",
            // @ts-ignore
            withCredentials: true,
            crossOrigin: true,
            method: "POST",
            data: JSON.stringify(card),
            contentType: "application/json",
        });
        return result;
    } catch (response: any) {
        if (response.status === 401) {
            const result: Result = await getActions()!.showLoginDialog();
            runInAction(() => {
                if (result == "ok") {
                    return clientCardCreate(card);
                }
            });
        }
        return clientCardCreate(card);
    }
}
export async function clientCardDelete(id: string): Promise<string> {
    try {
        const result = await reqwest.ajax<string>({
            url: config.SERVICE_URL + "/Admin/Dashboard/DeleteCard",
            type: "json",
            // @ts-ignore
            withCredentials: true,
            crossOrigin: true,
            method: "DELETE",
            data: JSON.stringify({ id }),
            contentType: "application/json",
        });
        return result;
    } catch (response: any) {
        const result: Result = await getActions()!.showLoginDialog();
        runInAction(() => {
            if (result == "ok") {
                return clientCardDelete(id);
            }
        });
        return clientCardDelete(id);
    }
}
