import * as React from "react";
import { useState } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { Dropdown } from "primereact/dropdown";
import { UserAction, User, Client, Language, SelectedUserLang } from "./types";

interface Properties {
    userActions: UserAction[];
    currentUser: User;
    clients?: Client[];
    preSelectedClient?: string;
    isAdmin?: boolean;
    currentLocation?: string;
    lang?: string;
    languages?: Language[];
    headerLeft: string;
    adminHeader?: string;
    dashboardHeader?: string;
    logOutHeader?: string;
    gotoAdmin?: string;

    handleSetLanguage?: (language: SelectedUserLang) => void;
    handleUserActions?: (userAction: UserAction) => void;
    handleSetClient?: (municipality: any) => void;
}

const Header: React.FC<Properties> = observer((props) => {
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);

    const handleNavCollapse = () => {
        setIsNavCollapsed((prev: any) => !prev);
    };

    const redirect = (path: string) => {
        const url = window.location.protocol + "//" + window.location.host + path;
        window.location.assign(url);
    };

    return (
        <>
            <nav className="navbar navbar-expand-md navbar-dark fixed-top " style={{ zIndex: "1" }}>
                <div className="container-fluid">
                    <div className="navbar-brand ">{props.headerLeft}</div>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="navbarCollapse"
                        data-bs-target="#navbarCollapse"
                        aria-controls="navbarCollapse"
                        aria-expanded={!isNavCollapsed ? true : false}
                        aria-label="Toggle navigation"
                        onClick={handleNavCollapse}
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={`${isNavCollapsed ? "collapse" : ""} navbar-collapse`} id="navbarCollapse">
                        <ul className="navbar-nav ms-auto mb-2 mb-md-0">
                            {props.handleSetClient ? (
                                <>
                                    <li className="nav-link text-secondary">
                                        <i className="fas fa-city"></i>
                                        <Dropdown
                                            className="nav-dropdown"
                                            value={props.preSelectedClient}
                                            options={props.clients}
                                            onChange={(e: any) => props.handleSetClient!(e.value)}
                                            optionLabel="name"
                                            placeholder={props.preSelectedClient}
                                            key={props.preSelectedClient}
                                        />
                                    </li>
                                </>
                            ) : null}
                            {props.handleUserActions ? (
                                <>
                                    <li className="nav-link text-secondary">
                                        <i className="fas fa-user"></i>
                                        {props.userActions.length > 0 ? (
                                            <Dropdown
                                                className="nav-dropdown"
                                                options={props.userActions}
                                                onChange={(e) => props.handleUserActions!(e.value)}
                                                optionLabel="translatedAction"
                                                placeholder={props.currentUser ? props.currentUser.name : ""}
                                            />
                                        ) : (
                                            <span className="ps-2">
                                                {props.currentUser ? <>{props.currentUser.name}</> : ""}
                                            </span>
                                        )}
                                    </li>
                                </>
                            ) : null}
                            {props.handleSetLanguage ? (
                                <>
                                    <li className="nav-link text-secondary">
                                        <i className="far fa-flag" />
                                        <Dropdown
                                            className="nav-dropdown"
                                            options={toJS(props.languages)}
                                            onChange={(e) => props.handleSetLanguage!(e.value)}
                                            optionLabel="name"
                                            placeholder={props.lang}
                                            key={props.lang}
                                        />
                                    </li>
                                </>
                            ) : null}
                            {props.isAdmin &&
                            props.currentLocation !== "/admin" &&
                            props.currentLocation !== "/amaisyOffice" ? (
                                <li className="nav-link text-secondary">
                                    <a href="#" onClick={() => redirect("/admin")} className="text-secondary">
                                        <i className="fas fa-user-shield"></i>
                                        <span className="ms-1">{props.adminHeader}</span>
                                    </a>
                                </li>
                            ) : null}
                            {props.currentLocation === "/admin" ? (
                                <li className="nav-link text-secondary">
                                    <a href="#" onClick={() => redirect("/")} className="text-secondary">
                                        <i className="fas fa-table"></i>
                                        <span className="ms-1">{props.dashboardHeader}</span>
                                    </a>
                                </li>
                            ) : null}
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
});

export default Header;
