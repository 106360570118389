import * as React from "react";
import { observer } from "mobx-react";
import { useState } from "react";
import { RadioButton } from "primereact/radiobutton";

const Radiobuttons: React.FC = observer(() => {
    const categories = [
        { name: "Accounting", key: "A1" },
        { name: "Marketing", key: "M1" },
        { name: "Production", key: "P1" },
        { name: "Research", key: "R1" },
    ];
    const [city, setCity] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(categories[1]);

    return (
        <div className="p-3  bg-light">
            <h2>Radiobuttons</h2>
            <h5>Basic</h5>
            <div className="p-field-radiobutton">
                <RadioButton
                    inputId="city11"
                    name="city"
                    value="Chicago"
                    onChange={(e) => setCity(e.value)}
                    checked={city === "Chicago"}
                />
                <label htmlFor="city11">Chicago</label>
            </div>
            <div className="p-field-radiobutton">
                <RadioButton
                    inputId="city22"
                    name="city"
                    value="Los Angeles"
                    onChange={(e) => setCity(e.value)}
                    checked={city === "Los Angeles"}
                />
                <label htmlFor="city22">Los Angeles</label>
            </div>
            <div className="p-field-radiobutton">
                <RadioButton
                    inputId="city33"
                    name="city"
                    value="New York"
                    onChange={(e) => setCity(e.value)}
                    checked={city === "New York"}
                />
                <label htmlFor="city33">New York</label>
            </div>
            <div className="p-field-radiobutton">
                <RadioButton
                    inputId="city44"
                    name="city"
                    value="San Francisco"
                    onChange={(e) => setCity(e.value)}
                    checked={city === "San Francisco"}
                />
                <label htmlFor="city44">San Francisco</label>
            </div>

            <h5>Dynamic Values, Preselection, Value Binding and Disabled Option</h5>
            {categories.map((category) => {
                return (
                    <div key={category.key} className="p-field-radiobutton">
                        <RadioButton
                            inputId={category.key}
                            name="category"
                            value={category}
                            onChange={(e) => setSelectedCategory(e.value)}
                            checked={selectedCategory.key === category.key}
                            disabled={category.key === "R1"}
                        />
                        <label htmlFor={category.key}>{category.name}</label>
                    </div>
                );
            })}
        </div>
    );
});

export default Radiobuttons;
