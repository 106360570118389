import { Translation } from "./translations";

export function officeLabelsTranslation() {
    const translations: Translation[] = [
        {
            key: "amaisy_office_label_area",
            value: "Area (m2)",
        },
        {
            key: "amaisy_office_label_section",
            value: "Section",
        },
        {
            key: "amaisy_office_label_Cost",
            value: "Cost",
        },
        {
            key: "amaisy_office_label_description",
            value: "Description",
        },
        {
            key: "amaisy_office_label_effectedelement",
            value: "Effected element",
        },
        {
            key: "amaisy_office_label_newname",
            value: "New name",
        },
        {
            key: "amaisy_office_label_sectionFromTo",
            value: "Section (from - to)",
        },
        {
            key: "amaisy_office_label_from",
            value: "From ",
        },
        {
            key: "amaisy_office_label_to",
            value: "To ",
        },
        {
            key: "amaisy_office_label_task",
            value: "Tasks",
        },
        {
            key: "amaisy_office_label_backtologin",
            value: "Back to login",
        },
        {
            key: "amaisy_office_label_search",
            value: "Search",
        },
        {
            key: "amaisy_office_label_collapseall",
            value: "Collapse all",
        },
        {
            key: "amaisy_office_label_expandall",
            value: "Expand all",
        },
        {
            key: "amaisy_office_label_yes",
            value: "Yes",
        },
        {
            key: "amaisy_office_label_no",
            value: "No",
        },
    ];
    return translations;
}
