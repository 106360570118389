import { observer } from "mobx-react";
import React, { useRef, useState } from "react";
import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import TemplatesPresenter, { TableContent } from "./presenter";
import { SelectButton, SelectButtonChangeParams } from "primereact/selectbutton";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import Translater from "shared/utils/localizer";
import { Checkbox } from "primereact/checkbox";
interface Properties {
    presenter: TemplatesPresenter;
}

const TemplatesHeader: React.FC<Properties> = observer(({ presenter }) => {
    let toast = useRef<Toast | null>(null);
    const buttonItems = [
        { label: Translater.translateText("amaisy_admin_template_table_header_cards_btn"), value: "cards" },
        { label: Translater.translateText("amaisy_admin_template_table_header_users_btn"), value: "users" },
    ];
    const [selectedButton, setSelectedButton] = useState<TableContent>(presenter.tableContent);
    const [standardefault, setDefaultTemplate] = useState<boolean>(false);
    const [standardefaultHasChanged, setstandardefaultHasChanged] = useState<boolean>(false);

    const updateDefaultTemplate = (value: boolean) => {
        setDefaultTemplate(value);
        setstandardefaultHasChanged(true);
        presenter.updateSelectedTemplateIsDefault(value);
    };

    const onItemChange = (e: DropdownChangeParams) => {
        presenter.setSelectedItem(e.value);
        presenter.templateIsSelected(e.value);
        setDefaultTemplate(presenter.selectedTemplate!.isDefault);
    };

    const onSingleButtonChange = (e: SelectButtonChangeParams) => {
        setSelectedButton(e.value);
        presenter.tableContent = e.value;
    };
    const onButtonNewClick = () => {
        presenter.addNewTemplate();
    };
    const onButtonSaveClick = () => {
        presenter.onSave(standardefaultHasChanged);
        if (toast && toast.current) {
            toast.current.show({
                severity: "success",
                summary: Translater.translateText("common_toast_save_success"),
                detail: Translater.translateText("common_toast_save_success"),
                contentClassName: "",
                life: 3000,
            });
        }
    };

    return (
        <>
            <Toast ref={toast} position="bottom-right" />
            <div className="d-flex pb-2">
                <div className="ms-3">
                    <Dropdown
                        value={presenter.selectedItem}
                        options={presenter.templateDropDownOptions}
                        onChange={onItemChange}
                        placeholder={Translater.translateText(
                            "amaisy_admin_template_table_header_dropdown_placeholder"
                        )}
                    />
                </div>
                {presenter.selectedTemplate && (
                    <div className="ps-5 d-flex align-items-center">
                        <div className="field-checkbox d-flex align-items-center">
                            <Checkbox
                                inputId="binary"
                                checked={standardefault}
                                onChange={(e) => updateDefaultTemplate(e.checked)}
                            />
                            <label htmlFor="binary">Sæt som standard</label>
                        </div>
                    </div>
                )}
                <div className="ms-auto">
                    <Button
                        label={Translater.translateText("common_create")}
                        icon="fas fa-plus"
                        className="p-button-primary"
                        iconPos="right"
                        tooltipOptions={{ position: "left" }}
                        tooltip={Translater.translateText("amaisy_office_tooltip_addnewtemplate")}
                        data-translate_key={Translater.translateText("amaisy_admin_client_dialog_header_new_template")}
                        onClick={onButtonNewClick}
                        aria-haspopup
                    />
                </div>
            </div>

            <div className="d-flex ps-3 pb-2">
                {presenter.selectedTemplate && (
                    <>
                        <div className="me-auto">
                            <SelectButton
                                value={selectedButton}
                                options={buttonItems}
                                onChange={onSingleButtonChange}
                            />
                        </div>
                        <div className="">
                            <Button
                                label={Translater.translateText("common_save")}
                                className="p-button-primary p-button-lg"
                                iconPos="right"
                                tooltipOptions={{ position: "left" }}
                                tooltip={Translater.translateText("common_savechanges")}
                                disabled={presenter.disabledSaveBtn}
                                onClick={onButtonSaveClick}
                            />
                        </div>
                    </>
                )}
            </div>
        </>
    );
});

export default TemplatesHeader;
