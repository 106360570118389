import React, { useEffect } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { observer } from "mobx-react";
import { toJS } from "mobx";

interface Properties {
    headerText?: string;
    cardImage?: string;
    linkText?: string;
    linkUrl?: string;
    description?: string;
    titleText?: string;
}

const header = (imageLink: string) => {
    if (imageLink === null) imageLink = "https://via.placeholder.com/200x150.png";
    return (
        <figure>
            <img alt="Card" src={imageLink} />
        </figure>
    );
};
const title = (titleText: string) => {
    return <h4>{titleText}</h4>;
};

const footer = (btnLabel: string, siteLink: string) => {
    //@ts-ignore

    return (
        <>
            <a
                className="d-flex justify-content-between btn btn-primary btn-lg animation-arrow"
                href={siteLink}
                target="_blank"
                //set tabindex so tab dont start going throug the menu
                tabIndex={1}
            >
                <div> {btnLabel}</div>
                <div className="">
                    <i className="fas fa-chevron-right" />
                </div>
            </a>
        </>
    );
};

const DashBoardCard: React.FC<Properties> = observer(
    ({ cardImage: imageLink, linkText: siteName, linkUrl: siteLink, description: siteText, headerText: titleText }) => {
        return (
            <>
                <Card
                    title={title(titleText!)}
                    footer={footer(siteName!, siteLink!)}
                    header={header(imageLink!)}
                    className="m-2 h-100"
                >
                    <p className="mb-0">{siteText}</p>
                </Card>
            </>
        );
    }
);

export default DashBoardCard;
