import { DashboardCard } from "apps/amaisy/src/types/mixedtypes";
import { PortalUser } from "apps/amaisy/src/types/userTypes";
import { observer } from "mobx-react";
import React from "react";
import DashBoardCard from "shared/components/card/src";

interface Properties {
    card?: DashboardCard;
    cardNumber: string;
    handleDrag: (evt: any) => void;
    handleDrop: (evt: any) => void;
    user?: PortalUser;
}

const CardItem: React.FC<Properties> = observer(({ card, cardNumber, handleDrag, handleDrop, user }) => {
    const getSiteLink = () => {
        if (user != undefined && card!.linkUrl.includes("@email@") && card!.linkUrl.includes("@name@")) {
            card!.linkUrl = card!.linkUrl.replace("@email@", user!.email);
            card!.linkUrl = card!.linkUrl.replace("@name@", user!.name);
            card!.linkUrl = card!.linkUrl.replace("@phone@", user!.mobileNumber ? user!.mobileNumber : "");
        }
        return card!.linkUrl;
    };
    if (!card) {
        return <></>;
    } else {
        return (
            <div className=" col-sm-4 col-lg-3  mb-4">
                <div
                    draggable={true}
                    id={cardNumber}
                    onDragOver={(ev) => ev.preventDefault()}
                    onDragStart={handleDrag}
                    onDrop={handleDrop}
                    className="h-100"
                >
                    <DashBoardCard
                        linkText={card.linkText}
                        linkUrl={getSiteLink()}
                        description={card.description}
                        cardImage={card.cardImage}
                        headerText={card.headerText}
                    />
                </div>
            </div>
        );
    }
});

export default CardItem;
