import React from "react";
import { observer } from "mobx-react";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import TemplatesPresenter from "./presenter";
import { Dialog } from "primereact/dialog";
import Translater from "shared/utils/localizer";
interface Properties {
    presenter: TemplatesPresenter;
}
interface TemplateData {
    templateName: string;
}

const TemplateForm: React.FC<Properties> = observer(({ presenter }) => {
    const defaultValues: TemplateData = {
        templateName: "",
    };

    const {
        control,
        formState: { errors },
        setValue,
        handleSubmit,
        reset,
    } = useForm({ defaultValues });

    const onSubmit = (data: TemplateData) => {
        presenter.createTemplate(data.templateName);
        presenter.showDialog = false;
    };

    const getFormErrorMessage = (name: keyof typeof errors) => {
        const error = errors[name];
        return error && <small className="p-error">{error.message}</small>;
    };

    const header = () => {
        return (
            <>
                <span>{Translater.translateText("amaisy_admin_template_dialog_header")}</span>
            </>
        );
    };
    const renderFooter = () => {
        return (
            <>
                <Button
                    label={Translater.translateText("common_cancel")}
                    onClick={() => presenter.toggleShowDialog()}
                    className="p-button-text p-button-secondary"
                />
                <Button
                    type="submit"
                    label={(Translater.translateText("common_save"), "Save")}
                    className="p-button-primary"
                />
            </>
        );
    };
    return (
        <>
            <Dialog
                header={header}
                visible={presenter.showDialog}
                onHide={() => presenter.toggleShowDialog()}
                className="p-dialog-sm"
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="content p-3 bg-light container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="templateName" className="form-label d-block">
                                        {Translater.translateText("amaisy_admin_template_dialog_input_template_name")}
                                    </label>
                                    <Controller
                                        name="templateName"
                                        control={control}
                                        rules={{
                                            required: Translater.translateText("common_required"),
                                        }}
                                        render={({ field, fieldState }) => (
                                            <InputText
                                                id={field.name}
                                                {...field}
                                                autoFocus
                                                type="text"
                                                className={fieldState.invalid ? "p-invalid" : ""}
                                            />
                                        )}
                                    />

                                    {getFormErrorMessage("templateName")}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-dialog-footer">{renderFooter()}</div>
                </form>
            </Dialog>
        </>
    );
});

export default TemplateForm;
