import { observer } from "mobx-react";
import React from "react";
import TemplateCards from "./templatecards";
import TemplatesPresenter from "./presenter";
import TemplateForm from "./templateform";
import TemplatesHeader from "./templatesheader";
import TemplateUsers from "./templateusers";

interface Properties {
    presenter: TemplatesPresenter;
}

const Templates: React.FC<Properties> = observer(({ presenter }) => {
    return (
        <>
            {presenter.showDialog && <TemplateForm presenter={presenter} />}
            <TemplatesHeader presenter={presenter} />
            {presenter.tableContent === "cards" ? (
                <TemplateCards presenter={presenter} />
            ) : (
                <TemplateUsers presenter={presenter} />
            )}
        </>
    );
});

export default Templates;
