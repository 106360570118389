import { runInAction } from "mobx";
import { UserMasterInfo, ChangePasswordResult } from "shared/types/src/user";
import reqwest from "shared/utils/request";
import { getActions } from "../actionregistry";
import { Result } from "../presenter";
import { PortalUser } from "../types/userTypes";

export async function loadClientUsers(): Promise<UserMasterInfo[]> {
    try {
        const result = await reqwest.ajax<UserMasterInfo[]>({
            url: config.SERVICE_URL + "/user/users",
            type: "json",
            // @ts-ignore
            withCredentials: true,
            crossOrigin: true,
            method: "GET",
        });
        return result;
    } catch (response: any) {
        if (response.status === 401) {
            const result: Result = await getActions()!.showLoginDialog();
            runInAction(() => {
                if (result == "ok") {
                    return loadClientUsers();
                }
            });
        }
    }
    return loadClientUsers();
}

export async function changePassword(currentPassword: string, newPassword: string): Promise<ChangePasswordResult> {
    let changePasswordResult: ChangePasswordResult = {
        success: false,
        passwordIsChanged: false,
    };
    const data = {
        currentPassword,
        newPassword,
    };

    changePasswordResult = await reqwest.ajax<ChangePasswordResult>({
        url: config.SERVICE_URL + "/user/changepassword",
        // @ts-ignore
        withCredentials: true,
        type: "json",
        contentType: "application/json",
        crossOrigin: true,
        method: "PUT",
        data: JSON.stringify(data),
    });
    return changePasswordResult;
}

export async function updateUser(
    name: string,
    phoneNumber: string,
    mobileNumber: string,
    language: string,
    email: string
): Promise<PortalUser> {
    const data = {
        name,
        phoneNumber,
        mobileNumber,
        language,
        email,
    };
    const result = await reqwest.ajax<PortalUser>({
        url: config.SERVICE_URL + "/user/updateuserdata",
        // @ts-ignore
        withCredentials: true,
        type: "json",
        contentType: "application/json",
        crossOrigin: true,
        method: "PUT",
        data: JSON.stringify(data),
    });
    return result;
}
