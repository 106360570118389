import { Translation } from "./translations";

export function adminTranslation() {
    const translations: Translation[] = [
        {
            key: "amaisy_admin_cards_admin_menu_header",
            value: "Cards administration",
        },
        {
            key: "amaisy_admin_portal_admin_menu_header",
            value: "Portal administration",
        },
        {
            key: "amaisy_admin_portal_admin_menu_all_clients",
            value: "All clients",
        },
        {
            key: "amaisy_admin_portal_admin_menu_card_template",
            value: "Card templates",
        },
        {
            key: "amaisy_admin_portal_admin_menu_all_cards",
            value: "All Cards",
        },
        {
            key: "amaisy_admin_client_table_header_link",
            value: "Portal settings > All clients",
        },
        {
            key: "amaisy_admin_client_table_column_client_name",
            value: "Clients",
        },
        {
            key: "amaisy_admin_client_table_column_id",
            value: "Id",
        },
        {
            key: "amaisy_admin_client_dialog_input_client_name",
            value: "Name*",
        },
        {
            key: "amaisy_admin_client_dialog_header_new_client",
            value: "Create new client",
        },
        {
            key: "amaisy_admin_client_dialog_header_new_template",
            value: "New template",
        },
        {
            key: "amaisy_admin_client_dialog_header_change_client",
            value: "Edit client",
        },
        {
            key: "amaisy_admin_template_table_column_headline",
            value: "Headline",
        },
        {
            key: "amaisy_admin_template_table_column_description",
            value: "Description",
        },
        {
            key: "amaisy_admin_template_table_column_link_name",
            value: "Link name",
        },
        {
            key: "amaisy_admin_template_table_column_link_url",
            value: "Link url",
        },
        {
            key: "amaisy_admin_template_table_column_name",
            value: "name",
        },
        {
            key: "amaisy_admin_template_table_header_dropdown_placeholder",
            value: "Choose",
        },
        {
            key: "amaisy_admin_user_table_column_name",
            value: "Name",
        },
        {
            key: "amaisy_admin_user_table_column_email",
            value: "Email",
        },
        {
            key: "amaisy_admin_template_table_header_cards_btn",
            value: "Map",
        },
        {
            key: "amaisy_admin_template_table_header_users_btn",
            value: "User",
        },
        {
            key: "amaisy_admin_template_table_template_tooltip_remove_access",
            value: "Remove card from template",
        },
        {
            key: "amaisy_admin_template_table_template_tooltip_add_access",
            value: "Add card to template",
        },
        {
            key: "amaisy_admin_template_table_user_tooltip_remove_access",
            value: "Remove access for user",
        },
        {
            key: "amaisy_admin_template_table_user_tooltip_add_access",
            value: "Add access to user",
        },
        {
            key: "amaisy_admin_template_dialog_header",
            value: "Create new template",
        },
        {
            key: "amaisy_admin_template_dialog_input_template_name",
            value: "Template Name*",
        },
        {
            key: "amaisy_admin_delete_confirm_dialog_header",
            value: "Confirm delete",
        },
        {
            key: "amaisy_admin_delete_confirm_dialog_message",
            value: "Do you wish to delete",
        },
        {
            key: "amaisy_admin_card_table_edit_tooltip",
            value: "Edit card",
        },
        {
            key: "amaisy_admin_card_table_delete_tooltip",
            value: "Delete Card",
        },
        {
            key: "amaisy_admin_card_table_column_name",
            value: "Name",
        },
        {
            key: "amaisy_admin_card_table_column_headline",
            value: "Headline",
        },
        {
            key: "amaisy_admin_card_table_column_description",
            value: "Description",
        },
        {
            key: "amaisy_admin_card_table_column_link_name",
            value: "Link name",
        },
        {
            key: "amaisy_admin_card_table_column_link_url",
            value: "Link url",
        },
        {
            key: "amaisy_admin_client_dialog_header_new_card",
            value: "Create new card",
        },
        {
            key: "amaisy_admin_client_dialog_header_change_card",
            value: "Edit Card",
        },
        {
            key: "amaisy_admin_card_dialog_input_name",
            value: "name*",
        },
        {
            key: "amaisy_admin_card_dialog_input_headline",
            value: "Headline*",
        },
        {
            key: "amaisy_admin_card_dialog_input_description",
            value: "Description*",
        },
        {
            key: "amaisy_admin_card_dialog_input_link_name",
            value: "Link name*",
        },
        {
            key: "amaisy_admin_card_dialog_input_link_url",
            value: "Link url*",
        },
    ];
    return translations;
}
