import * as React from "react";
import { observer } from "mobx-react";
import { useState } from "react";

const Typography: React.FC = observer(() => {
    return (
        <>
            <div className="p-3 bg-light">
                <h2>Typografi</h2>

                <h1>h1 Lorem Ipsum</h1>
                <h2>h2 Lorem Ipsum</h2>
                <h3>h3 Lorem Ipsum</h3>
                <h4>h4 Lorem Ipsum</h4>
                <h5>h5 Lorem Ipsum</h5>
                <h6>h6 Lorem Ipsum</h6>
                <p>pLorem Ipsum</p>
                <p>
                    <b>b Lorem Ipsum</b>
                </p>
                <p>
                    <i>i Lorem Ipsum</i>
                </p>
                <p>
                    <a href="#">a Lorem Ipsum</a>
                </p>
                <a href="#anchor-typography">
                    <h4>Typography</h4>
                </a>
            </div>
        </>
    );
});

export default Typography;
