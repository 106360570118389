import { PortalClient } from "../types/userTypes";
import reqwest from "shared/utils/request";
import { Result } from "../presenter";
import { getActions } from "../actionregistry";
import { runInAction } from "mobx";

export async function loadAllClients(): Promise<PortalClient[]> {
    try {
        const result = await reqwest.ajax<PortalClient[]>({
            url: config.SERVICE_URL + "/Client/GetAllClients",
            type: "json",
            method: "GET",
            contentType: "application/json",
            // @ts-ignore
            withCredentials: true,
        });
        return result;
    } catch (response: any) {
        if (response.status === 401) {
            const result: Result = await getActions()!.showLoginDialog();
            runInAction(() => {
                if (result == "ok") {
                    return loadAllClients();
                }
            });
        }
    }
    return [];
}
