import { clientCardCreate, clientCardDelete, loadDashBoardCards } from "apps/amaisy/src/service/clientcards";
import { clientCardUpdate } from "apps/amaisy/src/service/clientcards";
import { DashboardCard, DashboardCardBody } from "apps/amaisy/src/types/mixedtypes";
import { makeAutoObservable, IObservableArray, observable, runInAction } from "mobx";
import { imageRezier } from "apps/amaisy/src/service/imageresizer";

export default class CardsPresenter {
    cards: IObservableArray<DashboardCard> = observable([]);
    selectedCard: DashboardCard | null = null;

    showDialog: boolean = false;
    constructor() {
        makeAutoObservable(this);
    }

    loadCards = async () => {
        this.cards.clear();
        const loadedCards: DashboardCard[] = await loadDashBoardCards();
        runInAction(() => {
            this.cards.replace(loadedCards);
        });
    };

    updateCards = () => {
        this.loadCards();
    };

    setSelectedCard = (card: DashboardCard | null) => {
        this.selectedCard = card;
        this.showDialog = true;
    };
    toggleShowDialog = () => {
        this.showDialog = !this.showDialog;
    };

    initiateCreateCard = () => {
        const newCard: DashboardCard = {
            id: "",
            cardImage: "",
            cardName: "",
            description: "",
            headerText: "",
            linkText: "",
            linkUrl: "",
            sortOrder: 0,
        };
        this.selectedCard = newCard;
        this.showDialog = true;
    };

    deleteCard = async (card: DashboardCard) => {
        await clientCardDelete(card.id);
        runInAction(() => {
            const delcard = this.cards.find((x) => x.id === card.id);
            this.cards.remove(delcard!);
        });
    };

    update = (card: DashboardCard) => {
        if (card.id) {
            this.updateCard(card);
        } else {
            this.createCard(card);
        }
    };

    updateCard = async (card: DashboardCard) => {
        const cardToUpdate = this.cards.find((x) => x.id === card.id);
        cardToUpdate!.cardImage = card.cardImage;
        cardToUpdate!.cardName = card.cardName;
        cardToUpdate!.description = card.description;
        cardToUpdate!.headerText = card.headerText;
        cardToUpdate!.linkText = card.linkText;
        cardToUpdate!.linkUrl = card.linkUrl;

        await clientCardUpdate(cardToUpdate!);
    };
    createCard = async (card: DashboardCard) => {
        const newCard: DashboardCardBody = {
            cardImage: card.cardImage,
            cardName: card.cardName,
            description: card.description,
            headerText: card.headerText,
            linkText: card.linkText,
            linkUrl: card.linkUrl,
            sortOrder: 0,
        };

        const cardInReturn = await clientCardCreate(newCard);
        runInAction(() => {
            this.cards.push(cardInReturn);
        });
    };
    resizeImage = async (imageBase64: string) => {
        const base64 = { imageBase64: imageBase64 };
        const resizedBase64 = await imageRezier(base64);
        return resizedBase64.imageBase64;
    };
}
