import { loadDashBoardCards } from "apps/amaisy/src/service/clientcards";
import {
    loadTemplates,
    loadTemplateUsers,
    templateCreate,
    templateUpdate,
    templateUpdateCards,
    templateUpdateUsers,
} from "apps/amaisy/src/service/templates";
import { loadClientUsers } from "apps/amaisy/src/service/users";
import { DashboardCard, Template } from "apps/amaisy/src/types/mixedtypes";
import { makeAutoObservable, IObservableArray, observable, runInAction } from "mobx";
import { UserMasterInfo } from "shared/types/src/user";

export interface CardWithInclude {
    card: DashboardCard;
    included: boolean;
    existing: boolean;
}
export interface UserWithInclude {
    user: UserMasterInfo;
    included: boolean;
    existing: boolean;
}

export type TableContent = "cards" | "users";
export default class TemplatesPresenter {
    constructor() {
        makeAutoObservable(this);
    }
    templates: Template[] = observable([]);
    cards: IObservableArray<CardWithInclude> = observable([]);
    users: IObservableArray<UserWithInclude> = observable([]);
    selectedTemplate: Template | null = null;
    tableContent: TableContent = "cards";
    showDialog: boolean = false;
    selectedItem: string | null = null;
    disabledSaveBtn: boolean = true;
    templateDropDownOptions: { label: string; value: string }[] = [];
    loadTemplates = async () => {
        // @ts-ignore
        this.templates.clear();
        const loadedTemplates = await loadTemplates();
        runInAction(() => {
            // @ts-ignore
            this.templates.replace(loadedTemplates);
            const items = this.templates.map((x) => {
                return { label: x.templateName, value: x.id };
            });
            // @ts-ignore
            this.templateDropDownOptions.replace(items);
        });
    };

    updateTemplates = () => {
        this.loadTemplates();
    };

    toggleShowDialog = () => {
        this.showDialog = !this.showDialog;
    };

    updateSelectedTemplateIsDefault = (value: boolean) => {
        if (this.selectedTemplate) {
            this.selectedTemplate!.isDefault = value;
        }
    };

    templateIsSelected = async (id: string) => {
        const found = this.templates.find((x) => x.id === id);

        if (found) {
            this.selectedTemplate = found;
            const allCards = await loadDashBoardCards();
            const allUsers = await loadClientUsers();
            const templateUsers = await loadTemplateUsers(id);
            runInAction(() => {
                const cardswithinclude = allCards.map<CardWithInclude>((x) => {
                    return { card: x, included: false, existing: false };
                });
                found.dashboardCards.forEach((x) => {
                    const found = cardswithinclude.find((z) => z.card.id === x.id);
                    if (found) {
                        found.existing = true;
                        found.included = true;
                    }
                });
                this.cards.replace(cardswithinclude);

                const userswithinclude = allUsers.map<UserWithInclude>((x) => {
                    return { user: x, included: false, existing: false };
                });
                templateUsers.users.forEach((x) => {
                    const usr = userswithinclude.find((z) => z.user.id === x.id);
                    if (usr) {
                        usr.existing = true;
                        usr.included = true;
                    }
                });
                this.users.replace(userswithinclude);
            });
        }
    };

    addNewTemplate = () => {
        this.showDialog = true;
    };
    createTemplate = async (name: string) => {
        await templateCreate(name);

        this.loadTemplates();
    };
    saveTemplate = async () => {
        this.showDialog = false;
        let i = 0;
        const toBeUpdated = this.cards
            .filter((x) => x.included)
            .map((x) => {
                return { id: x.card.id, sortOrder: i++ };
            });

        const data = {
            templateId: this.selectedTemplate!.id,
            cards: toBeUpdated,
        };
        await templateUpdateCards(data);
    };
    saveUsers = async () => {
        this.showDialog = false;

        const toBeAdded = this.users
            .filter((x) => x.included && x.existing === false)
            .map((x) => {
                return x.user.id;
            });

        const toBeDeleted = this.users
            .filter((x) => x.included === false && x.existing === true)
            .map((x) => {
                return x.user.id;
            });

        const data = {
            templateId: this.selectedTemplate!.id,
            userIdsToAdd: toBeAdded,
            userIdsToRemove: toBeDeleted,
        };

        await templateUpdateUsers(data);
    };

    onSave = async (updateTemplateOnly: boolean) => {
        if (updateTemplateOnly && this.selectedTemplate!.isDefault) {
            await templateUpdate(this.selectedTemplate!);
        }
        if (this.cardsIsDirty()) {
            this.saveTemplate();
        }
        if (this.usersIsDirty()) {
            this.saveUsers();
        }
        this.disabledSaveBtn = true;
    };

    cardsIsDirty = () => {
        if (this.cards.find((x) => x.existing !== x.included)) {
            return true;
        }
        return false;
    };

    usersIsDirty = () => {
        if (this.users.find((x) => x.existing !== x.included)) {
            return true;
        }
        return false;
    };

    setIncludeCard = (data: CardWithInclude) => {
        const card = this.cards.find((x) => x.card.id === data.card.id);
        card!.included = true;
        this.disabledSaveBtn = false;
    };
    setExcludeCard = (data: CardWithInclude) => {
        const card = this.cards.find((x) => x.card.id === data.card.id);
        card!.included = false;
        this.disabledSaveBtn = false;
    };

    setIncludeUser = (data: UserWithInclude) => {
        const user = this.users.find((x) => x.user.id === data.user.id);
        user!.included = true;
        this.disabledSaveBtn = false;
    };
    setExcludeUser = (data: UserWithInclude) => {
        const user = this.users.find((x) => x.user.id === data.user.id);
        user!.included = false;
        this.disabledSaveBtn = false;
    };

    clearSelectedTemplate = () => {
        this.cards.replace([]);
        this.users.replace([]);
        this.loadTemplates();
        this.setSelectedItem(null);
        this.selectedTemplate = null;
    };

    setSelectedItem = (item: string | null) => {
        this.selectedItem = item;
    };
}
