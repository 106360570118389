import { observer } from "mobx-react";
import React from "react";
import TemplatesPresenter, { CardWithInclude } from "./presenter";
import { toJS } from "mobx";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import Translater from "shared/utils/localizer";

interface Properties {
    presenter: TemplatesPresenter;
}

const TemplateCards: React.FC<Properties> = observer(({ presenter }) => {
    const imageBodyTemplate = (data: CardWithInclude) => {
        return (
            <>
                <img src={data.card.cardImage} className="product-image3 " style={{ maxHeight: "75px" }} />
            </>
        );
    };
    const includeButtonClicked = (data: CardWithInclude) => {
        presenter.setIncludeCard(data);
    };
    const excludeButtonClicked = (data: CardWithInclude) => {
        presenter.setExcludeCard(data);
    };
    const buttonBodyTemplate = (data: CardWithInclude) => {
        if (data.included) {
            return (
                <div className="text-center">
                    <Button
                        icon="fas fa-eye"
                        className="p-button-success"
                        tooltip={Translater.translateText("amaisy_admin_template_table_template_tooltip_remove_access")}
                        tooltipOptions={{ position: "left" }}
                        onClick={() => excludeButtonClicked(data)}
                    ></Button>
                </div>
            );
        } else {
            return (
                <div className="text-center">
                    <Button
                        icon="fas fa-eye-slash"
                        className="p-button-danger"
                        tooltip={Translater.translateText("amaisy_admin_template_table_template_tooltip_add_access")}
                        onClick={() => includeButtonClicked(data)}
                        tooltipOptions={{ position: "left" }}
                    ></Button>
                </div>
            );
        }
    };

    const cardItems = toJS(presenter.cards);

    return (
        <>
            <div className="datatable-responsive-demo ps-3 pb-5">
                <DataTable
                    value={cardItems}
                    sortField={"included"}
                    sortOrder={-1}
                    className="p-datatable-responsive-demo p-0"
                >
                    <Column
                        field="card.cardName"
                        header={Translater.translateText("amaisy_admin_template_table_column_name")}
                        filter={true}
                    />
                    <Column
                        field="card.headerText"
                        header={Translater.translateText("amaisy_admin_template_table_column_headline")}
                        filter={true}
                    />
                    <Column
                        field="card.description"
                        header={Translater.translateText("amaisy_admin_template_table_column_description")}
                        filter={true}
                    />
                    <Column
                        field="card.linkText"
                        header={Translater.translateText("amaisy_admin_template_table_column_link_name")}
                        filter={true}
                    />
                    <Column
                        field="card.linkUrl"
                        header={Translater.translateText("amaisy_admin_template_table_column_link_url")}
                        filter={true}
                    />
                    <Column field="card.cardImage" body={imageBodyTemplate} />
                    <Column field="included" body={buttonBodyTemplate} sortable={true} />
                </DataTable>
            </div>
        </>
    );
});

export default TemplateCards;
