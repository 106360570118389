import React, { useState } from "react";

import { observer } from "mobx-react";
import { InputText } from "primereact/inputtext";

const Inputs: React.FC = observer(() => {
    const [value1, setValue1] = useState("");
    const [value2, setValue2] = useState("");
    const [value3, setValue3] = useState("");
    const [value4, setValue4] = useState("");
    const [value5, setValue5] = useState("");

    return (
        <div>
            <div className="p-3  bg-light">
                <h2>Inputs</h2>
                {/* <h5>Basic</h5> */}
                <div className="form-group">
                    <label className="form-label d-block" htmlFor="input_basic">
                        Basic input
                    </label>
                    <InputText id="input_basic" value={value1} onChange={(e) => setValue1(e.target.value)} />
                    <span className="p-ml-2">{value1}</span>
                </div>
                <div className="form-group">
                    <span className="p-float-label">
                        <InputText id="username" value={value2} onChange={(e) => setValue2(e.target.value)} />
                        <label htmlFor="username">Floating label</label>
                    </span>
                </div>
                <div className="form-group">
                    <label className="form-label d-block" htmlFor="input_L_icon">
                        Left Icon
                    </label>
                    <span className="p-input-icon-left">
                        <i className="fas fa-search" />
                        <InputText
                            id="input_L_icon"
                            value={value3}
                            onChange={(e) => setValue3(e.target.value)}
                            placeholder="Search"
                        />
                    </span>
                </div>

                <div className="form-group">
                    <label className="form-label d-block" htmlFor="input_R_icon">
                        Right Icon
                    </label>
                    <span className="p-input-icon-right">
                        <i className="fas fa-spin fa-spinner" />
                        <InputText id="input_R_icon" value={value4} onChange={(e) => setValue4(e.target.value)} />
                    </span>
                </div>
                <div className="form-group">
                    <label htmlFor="username1" className="form-label d-block">
                        Help Text
                    </label>
                    <InputText id="username1" aria-describedby="username1-help" className="d-block" />
                    <small id="username1-help" className="d-block">
                        Enter your username to reset your password.
                    </small>
                </div>

                <div className="form-group">
                    <label htmlFor="username2" className="d-block">
                        Invalid Username
                    </label>
                    <InputText id="username2" aria-describedby="username2-help" className="p-invalid d-block" />
                    <small id="username2-help" className="p-error p-d-block">
                        Username is not available.
                    </small>
                </div>

                <div className="form-group">
                    <label htmlFor="disabled" className="d-block">
                        Disabled
                    </label>
                    <InputText id="disabled" value={value5} disabled />
                </div>

                <div className="sizes">
                    <div className="form-group">
                        <label htmlFor="small" className="d-block">
                            small
                        </label>
                        <InputText id="small" type="text" className="p-inputtext-sm d-block " placeholder="Small" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="normal" className="d-block">
                            normal
                        </label>
                        <InputText id="normal" type="text" className="d-block " placeholder="Normal" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="large" className="p-d-block">
                            large
                        </label>
                        <InputText id="large" type="text" className="p-inputtext-lg d-block" placeholder="Large" />
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Inputs;
