import { observable, IObservableArray, makeAutoObservable, toJS, makeObservable, runInAction } from "mobx";
import { getActions } from "../../actionregistry";
import { Language, LanguageLookup } from "shared/types/src/basetypes";
import { PortalUser } from "../../types/userTypes";

export class UserDetailPresenter {
    userDetailDialogVisible: boolean = false;
    languagesLookup: IObservableArray<LanguageLookup> = observable([]);
    currenUserLangName: string = "";
    currentUser?: PortalUser;

    constructor() {
        makeAutoObservable(this);
    }

    setUserDetailDialogVisible = (value: boolean) => {
        this.userDetailDialogVisible = value;
    };

    saveUserDetails = async (name: string, phoneNumber: string, mobileNumber: string, email: string, lang: string) => {
        const user: PortalUser = await getActions()!.updateUser(name, phoneNumber, mobileNumber, lang, email);
        runInAction(() => {
            if (user !== undefined) {
                this.currentUser = user;
                this.setUserDetailDialogVisible(false);
            }
        });
    };

    setUser = (currentUser: PortalUser) => {
        this.currentUser = currentUser;
        this.setCurrentLanguage();
    };

    setLanguagesLookup = (languages: Language[]) => {
        const languagesLookup: LanguageLookup[] = languages.map((language: Language) => {
            return {
                code: language.code,
                name: language.name,
            };
        });
        this.languagesLookup.replace(languagesLookup);
    };

    setCurrentLanguage = () => {
        this.currenUserLangName = this.languagesLookup.filter((x) => x.code === this.currentUser?.language)[0].name;
    };
}
