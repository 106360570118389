import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import Cards from "./cards";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { AdministrationPresenter } from "./presenter";
import { Accordion, AccordionEventParams, AccordionTab } from "primereact/accordion";
import { ActiveMenuTypes } from "../../types/mixedtypes";
import Templates from "./templates";
import Translater from "shared/utils/localizer";

interface Properties {
    presenter: AdministrationPresenter;
    isSiteAdmin: boolean;
}

const Administration: React.FC<Properties> = observer(({ presenter }) => {
    const [ChosenComponent, setChosenComponent] = useState<JSX.Element | null>(null);
    const [showSidebar, setShowSidebar] = useState(true);

    useEffect(() => {
        if (sessionStorage.getItem("active") !== null) {
            const activeComponent = JSON.parse(sessionStorage.getItem("active")!);
            const loaderFunction = presenter.mappedChosenComponents.get(activeComponent.comp)!.loader;
            if (loaderFunction !== null) {
                loaderFunction!();
            }
            setChosenComponent(presenter.mappedChosenComponents.get(activeComponent.comp)!.component);
            presenter.updateActive(activeComponent.comp);
            presenter.replaceActiveIndex(activeComponent.index);
            activeMenuClass(activeComponent.comp);
        }
    }, []);

    const showCardsAdmin = () => {
        presenter.updateActive("SHOW ALL CARDS");
        presenter.cardsPresenter.updateCards();
        setChosenComponent(<Cards presenter={presenter.cardsPresenter} />);
    };

    const showCardsTemplatesAdmin = () => {
        presenter.updateActive("SHOW CARDS TEMPLATE");
        presenter.templatePresenter.loadTemplates();
        setChosenComponent(<Templates presenter={presenter.templatePresenter} />);
    };

    const onTabOpen = (e: AccordionEventParams) => {
        presenter.addActiveIndex(e.index);
    };
    const onTabClose = (e: AccordionEventParams) => {
        presenter.removeFromActiveIndex(e.index);
    };

    const activeMenuClass = (menuType: ActiveMenuTypes) => {
        return presenter.getIsActive(menuType) ? "menu_active" : "";
    };

    return (
        <>
            <Button
                icon="fas fa-server"
                onClick={() => setShowSidebar(true)}
                className="btn-open-sidebar btn p-button-primary p-button-icon-only"
                tooltip={Translater.translateText("amaisy_sidebar_menu_open_tooltip")}
                style={{ padding: "0.1rem 0.2rem", left: "0", top: "43px", position: "absolute" }}
            />
            <div
                className={
                    showSidebar == true
                        ? "openSidebarContentPos container-fluid bg-white h-100 pt-5 pb-5 "
                        : "closedSidebarContentPos container-fluid bg-white h-100 pt-5 pb-5 "
                }
            >
                <div className="row">
                    <div className="col-12">{ChosenComponent} </div>
                </div>
            </div>
            <Sidebar visible={showSidebar} onHide={() => setShowSidebar(false)} dismissable={false} modal={false}>
                <Accordion multiple activeIndex={presenter.activeIndex} onTabOpen={onTabOpen} onTabClose={onTabClose}>
                    <AccordionTab header={Translater.translateText("amaisy_admin_cards_admin_menu_header")}>
                        <a
                            onClick={() => showCardsTemplatesAdmin()}
                            className={activeMenuClass("SHOW CARDS TEMPLATE")}
                            style={{ color: "black", cursor: "pointer", display: "block" }}
                        >
                            {Translater.translateText("amaisy_admin_portal_admin_menu_card_template")}
                        </a>
                        <a
                            onClick={() => showCardsAdmin()}
                            className={activeMenuClass("SHOW ALL CARDS")}
                            style={{ color: "black", cursor: "pointer", display: "block" }}
                        >
                            {Translater.translateText("amaisy_admin_portal_admin_menu_all_cards")}
                        </a>
                    </AccordionTab>
                </Accordion>
            </Sidebar>
        </>
    );
});

export default Administration;
