import * as React from "react";
import { observer } from "mobx-react";
import { useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Badge } from "primereact/badge";
import { Password } from "primereact/password";
import { Divider } from "primereact/divider";
const Buttons: React.FC = observer(() => {
    const [value1, setValue1] = useState("");
    const [value2, setValue2] = useState("");
    const [value3, setValue3] = useState("");
    const [value4, setValue4] = useState("");

    const header = <h5>Vælg et kodeord</h5>;
    const footer = (
        <React.Fragment>
            <Divider />
            <p className="mt-2">Forslag</p>
            <ul className=" mt-0">
                <li>At least one lowercase</li>
                <li>At least one uppercase</li>
                <li>At least one numeric</li>
                <li>Minimum 8 characters</li>
            </ul>
        </React.Fragment>
    );
    return (
        <>
            <div className="p-3  bg-light ">
                <h2>Password</h2>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label className="form-label d-block" htmlFor="basic">
                                Basic
                            </label>
                            <Password
                                inputId="basic"
                                autoComplete="new-password"
                                value={value1}
                                onChange={(e) => setValue1(e.target.value)}
                                feedback={false}
                            />
                        </div>
                        <div className="form-group">
                            <label className="form-label d-block" htmlFor="pwMeter">
                                Password Meter
                            </label>
                            <Password
                                inputId="pwMeter"
                                promptLabel="Indtast kodeord"
                                weakLabel="Koden er vist lidt for nem at gætte!"
                                mediumLabel="Koden kunne godt være sværere at gætte!"
                                strongLabel="Dette er en stærk kode :)"
                                value={value2}
                                onChange={(e) => setValue2(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label className="form-label d-block" htmlFor="showPw">
                                Show Password
                            </label>
                            <Password
                                inputId="showPw"
                                promptLabel="Indtast kodeord"
                                weakLabel="Koden er vist lidt for nem at gætte!"
                                mediumLabel="Koden kunne godt være sværere at gætte!"
                                strongLabel="Dette er en stærk kode :)"
                                value={value3}
                                onChange={(e) => setValue3(e.target.value)}
                                toggleMask
                            />
                        </div>
                        <div className="form-group">
                            <label className="form-label d-block" htmlFor="templateId">
                                Templating
                            </label>
                            <Password
                                inputId="templateId"
                                promptLabel="Indtast kodeord"
                                weakLabel="Koden er vist lidt for nem at gætte!"
                                mediumLabel="Koden kunne godt være sværere at gætte!"
                                strongLabel="Dette er en stærk kode :)"
                                value={value4}
                                onChange={(e) => setValue4(e.target.value)}
                                header={header}
                                footer={footer}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});

export default Buttons;
