import React, { useState, useEffect, useRef } from "react";
import { Skeleton } from "primereact/skeleton";
import { observer } from "mobx-react";
import { Dropdown } from "primereact/dropdown";

const Dropdowns: React.FC = observer(() => {
    const [lazyItems, setLazyItems] = useState<any>([]);
    const [lazyLoading, setLazyLoading] = useState<any>(false);
    const [selectedCity1, setSelectedCity1] = useState<any>(null);
    const [selectedCity2, setSelectedCity2] = useState<any>(null);
    const [selectedCountry, setSelectedCountry] = useState<any>(null);
    const [selectedGroupedCity, setSelectedGroupedCity] = useState<any>(null);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [selectedItem2, setSelectedItem2] = useState<any>(null);

    let loadLazyTimeout = useRef(null);

    const cities = [
        { name: "New York", code: "NY" },
        { name: "Rome", code: "RM" },
        { name: "London", code: "LDN" },
        { name: "Istanbul", code: "IST" },
        { name: "Paris", code: "PRS" },
    ];

    const countries = [
        { name: "Australia", code: "AU" },
        { name: "Brazil", code: "BR" },
        { name: "China", code: "CN" },
        { name: "Egypt", code: "EG" },
        { name: "France", code: "FR" },
        { name: "Germany", code: "DE" },
        { name: "India", code: "IN" },
        { name: "Japan", code: "JP" },
        { name: "Spain", code: "ES" },
        { name: "United States", code: "US" },
    ];

    const groupedCities = [
        {
            label: "Germany",
            code: "DE",
            items: [
                { label: "Berlin", value: "Berlin" },
                { label: "Frankfurt", value: "Frankfurt" },
                { label: "Hamburg", value: "Hamburg" },
                { label: "Munich", value: "Munich" },
            ],
        },
        {
            label: "USA",
            code: "US",
            items: [
                { label: "Chicago", value: "Chicago" },
                { label: "Los Angeles", value: "Los Angeles" },
                { label: "New York", value: "New York" },
                { label: "San Francisco", value: "San Francisco" },
            ],
        },
        {
            label: "Japan",
            code: "JP",
            items: [
                { label: "Kyoto", value: "Kyoto" },
                { label: "Osaka", value: "Osaka" },
                { label: "Tokyo", value: "Tokyo" },
                { label: "Yokohama", value: "Yokohama" },
            ],
        },
    ];

    const items = Array.from({ length: 100000 }).map((_, i) => ({ label: `Item #${i}`, value: i }));

    useEffect(() => {
        setLazyItems(Array.from({ length: 100000 }));
        setLazyLoading(false);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onCityChange = (e: { value: any }) => {
        setSelectedCity1(e.value);
    };

    const onCityChange2 = (e: { value: any }) => {
        setSelectedCity2(e.value);
    };

    const onCountryChange = (e: { value: any }) => {
        setSelectedCountry(e.value);
    };

    //    const onGroupedCityChange(e: {value: any}) {
    //         setSelectedGroupedCity(e.value);
    //     }

    const onItemChange = (e: { value: any }) => {
        setSelectedItem(e.value);
    };

    const onLazyItemChange = (e: { value: any }) => {
        setSelectedItem2(e.value);
    };

    // const onLazyLoad = (event) => {
    //     setLazyLoading(true);

    //     if (loadLazyTimeout) {
    //         clearTimeout(loadLazyTimeout);
    //     }

    //     //imitate delay of a backend call
    //     loadLazyTimeout = setTimeout(() => {
    //         const { first, last } = event;
    //         const _lazyItems = [...lazyItems];

    //         for (let i = first; i < last; i++) {
    //             _lazyItems[i] = { label: `Item #${i}`, value: i };
    //         }

    //         setLazyItems(_lazyItems);
    //         setLazyLoading(false);
    //     }, Math.random() * 1000 + 250);
    // }

    const selectedCountryTemplate = (option: { name: string; code: string }, props: { placeholder: string }) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    {/* <img alt={option.name} src="showcase/demo/images/flag_placeholder.png" onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} className={`flag flag-${option.code.toLowerCase()}`} /> */}
                    <div>{option.name}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const countryOptionTemplate = (option: any) => {
        return (
            <div className="country-item">
                {/* <img alt={option.name} src="showcase/demo/images/flag_placeholder.png" onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} className={`flag flag-${option.code.toLowerCase()}`} /> */}
                <div>{option.name}</div>
            </div>
        );
    };

    const groupedItemTemplate = (option: any) => {
        return (
            <div className="p-d-flex p-ai-center country-item">
                {/* <img alt={option.label} src="showcase/demo/images/flag_placeholder.png" onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} className={`flag flag-${option.code.toLowerCase()}`} /> */}
                <div>{option.label}</div>
            </div>
        );
    };

    return (
        <div className="p-3  bg-light">
            <h2>Dropdown</h2>

            <div className="form-group">
                <label className="form-label d-block" htmlFor="dpBasic">
                    Basic
                </label>
                <Dropdown
                    inputId="dpBasic"
                    value={selectedCity1}
                    options={cities}
                    onChange={onCityChange}
                    optionLabel="name"
                    placeholder="Select a City"
                />
            </div>

            <div className="form-group">
                <label className="form-label d-block" htmlFor="dpEditable">
                    Editable
                </label>
                <Dropdown
                    inputId="dpEditable"
                    value={selectedCity2}
                    options={cities}
                    onChange={onCityChange2}
                    optionLabel="name"
                    editable
                />
            </div>

            <div className="form-group">
                <label className="form-label d-block" htmlFor="dpAdvanced">
                    Advanced with Templating, Filtering and Clear Icon
                </label>
                <Dropdown
                    inputId="dpAdvanced"
                    value={selectedCountry}
                    options={countries}
                    onChange={onCountryChange}
                    optionLabel="name"
                    filter
                    showClear
                    filterBy="name"
                    placeholder="Select a Country"
                    valueTemplate={selectedCountryTemplate}
                    itemTemplate={countryOptionTemplate}
                />
            </div>

            {/* <h5>Virtual Scroll (100000 Items)</h5>
                <Dropdown value={selectedItem} options={items} onChange={onItemChange} virtualScrollerOptions={{ itemSize: 31 }} placeholder="Select Item"/> */}

            {/* <h5>Virtual Scroll (100000 Items) and Lazy</h5>
                <Dropdown value={selectedItem2} options={lazyItems} onChange={onLazyItemChange} virtualScrollerOptions={{ lazy: true, onLazyLoad: onLazyLoad, itemSize: 31, showLoader: true, loading: lazyLoading, delay: 250, loadingTemplate: (options) => {
                    return (
                        <div className="p-d-flex p-ai-center p-p-2" style={{ height: '31px' }}>
                            <Skeleton width={options.even ? '60%' : '50%'} height="1rem" />
                        </div>
                    )}
                }} placeholder="Select Item"/> */}
        </div>
    );
});

export default Dropdowns;
