import * as React from "react";
import { observer } from "mobx-react";
import { useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Badge } from "primereact/badge";

const Buttons: React.FC = observer(() => {
    return (
        <>
            <div className="p-3 bg-light">
                <h2>Badge</h2>
                <h5>Numbers</h5>
                <Badge value="2" className="me-2"></Badge>
                <Badge value="Long badge" className="me-2"></Badge>
                <Badge value="8" severity="info" className="me-2 p-badge-secondary"></Badge>

                <Badge value="8" severity="success" className="me-2"></Badge>
                <Badge value="4" severity="info" className="me-2"></Badge>
                <Badge value="12" severity="warning" className="me-2"></Badge>
                <Badge value="3" severity="danger"></Badge>

                <h5 className="p-mb-4">Positioned Badge</h5>

                <i className="far fa-bell me-4 p-text-secondary p-overlay-badge" style={{ fontSize: "2rem" }}>
                    <Badge value="2"></Badge>
                </i>
                <i className="far fa-calendar me-4 p-text-secondary p-overlay-badge" style={{ fontSize: "2rem" }}>
                    <Badge value="10+" severity="danger"></Badge>
                </i>
                <i className="far fa-envelope p-text-secondary p-overlay-badge" style={{ fontSize: "2rem" }}>
                    <Badge severity="danger"></Badge>
                </i>

                <h5>Button Badge</h5>
                <Button type="button" label="Emails" className="me-2">
                    <Badge value="8" severity="info"></Badge>
                </Button>
                <Button type="button" label="Messages" icon="far fa-users" className="p-button-warning">
                    <Badge value="8" severity="danger"></Badge>
                </Button>

                <h5>Sizes</h5>
                <Badge value="2" className="me-2"></Badge>
                <Badge value="4" className="me-2" size="large" severity="warning"></Badge>
                <Badge value="6" size="xlarge" severity="success"></Badge>
            </div>
        </>
    );
});

export default Buttons;
