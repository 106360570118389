import { DashboardCard } from "../types/mixedtypes";
import reqwest from "shared/utils/request";
import { getActions } from "apps/amaisy/src/actionregistry";
import { runInAction } from "mobx";
import { Result } from "../presenter";

export async function loadDashBoardCards(clientId: string): Promise<DashboardCard[]> {
    try {
        const result = await reqwest.ajax<DashboardCard[]>({
            url: config.SERVICE_URL + "/Admin/Dashboard/UserCards/",
            type: "json",
            // @ts-ignore
            withCredentials: true,
            crossOrigin: true,
            method: "GET",
        });
        return result;
    } catch (response: any) {
        if (response.status === 401) {
            const result: Result = await getActions()!.showLoginDialog();
            runInAction(() => {
                if (result == "ok") {
                    return loadDashBoardCards(clientId);
                }
            });
        }
    }
    return [];
}
