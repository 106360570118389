import React, { useDebugValue, useMemo, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Actions from "./actions";
import Header from "shared/components/header";
import Footer from "shared/components/footer/";
import "../../../shared/style/sass/amaisy.scss";
import Board from "./component/dashboard";
import Administration from "./component/administration";
import ProtoType from "../../../shared/components/prototype/src";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { PortalClient } from "./types/userTypes";
import { runInAction } from "mobx";
import logo from "../../../shared/style/graphics/sweco-logo.svg";
import { ApplicationPresenter } from "./presenter";
import { useEffect } from "react";
import Translater from "shared/utils/localizer";
import { SelectedUserLang, UserAction } from "shared/types/src/user";
import { Toast } from "primereact/toast";
import { initToaster } from "shared/components/toaster";
import { getLoginUrl } from "./service/userservice";

interface Properties {
    actions: Actions;
    presenter: ApplicationPresenter;
}

const Application: React.FC<Properties> = observer(({ actions, presenter }) => {
    const [translationsLoaded, setTranslationsLoaded] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const channel = useMemo(() => new BroadcastChannel("lang"), []);
    const [selectedClient, setSelectedClient] = useState(presenter.selectedClient);
    const toast = React.createRef<Toast>();

    useEffect(() => {
        initToaster(toast.current!);
        if (!isAuthenticated) {
            authUser();
        }
        initToaster(toast.current!);
        channel.addEventListener("message", (event) => {
            if (event.data.lang !== presenter.currentLanguage) presenter.setLanguage(event.data.lang);
        });
    }, [channel, presenter.currentLanguage]);

    const authUser = async () => {
        const authenticated = await actions.authUserById();

        if (!authenticated) {
            redirectToLogin();
        } else {
            setIsAuthenticated(authenticated);
            setSelectedClient(presenter.selectedClient!);
            actions.setLanguages();
            loadTranslations();
        }
    };

    const redirectToLogin = async () => {
        window.location.href = await getLoginUrl(config.BASE_URL + location.pathname, location.search);
    };

    const loadTranslations = async () => {
        const translationPromise = Translater.loadTranslations(config.SERVICE_URL, presenter.currentLanguage, true);
        Promise.all([translationPromise]).then(() => {
            runInAction(() => {
                setTranslationsLoaded(true);
            });
        });
    };

    const changeClient = async (client: PortalClient) => {
        await actions.changeUserClient(client.id!);

        runInAction(() => {
            if (window.location.pathname == "/admin") {
                for (let [key, value] of actions.controller.presenters.administrationPresenter.mappedMenu) {
                    if (value === true) {
                        if (key === "SHOW ALL CARDS") {
                            actions.controller.presenters.administrationPresenter.cardsPresenter.updateCards();
                        } else if (key === "SHOW CARDS TEMPLATE") {
                            actions.controller.presenters.administrationPresenter.templatePresenter.updateTemplates();
                            actions.controller.presenters.administrationPresenter.templatePresenter.clearSelectedTemplate();
                        }
                    }
                }
            }
            setSelectedClient(client.name);
        });
    };

    const setLanguage = async (language: SelectedUserLang) => {
        channel.postMessage({ lang: language.code });
        presenter.setLanguage(language.code);
    };
    const userAction = (userAction: UserAction) => {
        if (userAction.action === "RESET CARDS ORDER") {
            localStorage.clear();
            actions.setCards(presenter.user!.selectedClient);
        } else if (userAction.action === "USER DETALS") {
            presenter.userDetailPresenter.setUserDetailDialogVisible(true);
        }
    };

    const getUserClients = () => {
        const clients: PortalClient[] = toJS(presenter.user!.clients);
        return clients;
    };

    const getUserActions = () => {
        const userActions: UserAction[] = toJS(presenter.userActions);
        return userActions;
    };

    const redirectToScenarios = () => {
        const url = config.BASE_URL + window.location.pathname;
        window.location.assign(url);
    };

    return (
        <>
            {isAuthenticated && translationsLoaded ? (
                <>
                    <Header
                        gotoAdmin="/admin"
                        handleUserActions={userAction}
                        userActions={getUserActions()}
                        clients={getUserClients()}
                        handleSetClient={changeClient}
                        preSelectedClient={selectedClient}
                        isAdmin={presenter.isAdmin}
                        currentLocation={window.location.pathname}
                        lang={presenter.currentLanguage}
                        languages={presenter.languages}
                        handleSetLanguage={setLanguage}
                        adminHeader={Translater.translateText("amaisy_header_admin")}
                        dashboardHeader={Translater.translateText("amaisy_header_dashboard")}
                        headerLeft={Translater.translateText("amaisy_header_left")}
                        logOutHeader={Translater.translateText("common_LogOut")}
                        currentUser={presenter.user!}
                    />
                    <>
                        <div className="content content-office" style={{ paddingTop: "43px", paddingBottom: "0" }}>
                            <Routes>
                                {window.location.pathname == "/amaisyoffice" ? (
                                    <>{redirectToScenarios()}</>
                                ) : (
                                    <>
                                        <Route
                                            path="/"
                                            element={
                                                <Board
                                                    presenter={actions.controller.presenters.boardPresenter}
                                                    newUser={false}
                                                />
                                            }
                                        />
                                        <Route
                                            path="/newUser"
                                            element={
                                                <Board
                                                    presenter={actions.controller.presenters.boardPresenter}
                                                    newUser={true}
                                                />
                                            }
                                        />
                                        <Route
                                            path="/admin"
                                            element={
                                                presenter.isAdmin ? (
                                                    <Administration
                                                        presenter={
                                                            actions.controller.presenters.administrationPresenter
                                                        }
                                                        isSiteAdmin={presenter.userRole === "SiteAdministrator"}
                                                    />
                                                ) : null
                                            }
                                        />
                                        <Route
                                            path="prototype/*"
                                            element={presenter.isAdmin ? <ProtoType /> : <Navigate replace to="/" />}
                                        />
                                    </>
                                )}
                            </Routes>
                        </div>
                        <Toast ref={toast} appendTo={document.body} position="bottom-right" />
                    </>
                    <Footer logo={logo} />
                </>
            ) : null}
        </>
    );
});
export default Application;
