import { adminTranslation } from "./adminTranslations";
import { commonTranslation } from "./commonTranslations";
import { officeTooltipTranslation } from "./officeTooltipTranslations";
import { officeTranslation } from "./officeTranslations";
import { officeToastTranslation } from "./officeToastTranslations";
import { officeValidationTranslation } from "./officeValidationTranslations";
import { officeLabelsTranslation } from "./officeLabelsTranslations";

export interface Translation {
    key: string;
    value: string;
}

export function amaisyTranslation() {
    const translations: Translation[] = [
        {
            key: "amaisy_header_left",
            value: "Portal",
        },
        {
            key: "amaisy_header_admin",
            value: "Admin",
        },
        {
            key: "amaisy_header_dashboard",
            value: "Dashboard",
        },
        {
            key: "amaisy_sidebar_menu_open_tooltip",
            value: "Open menu",
        },
        {
            key: "amaisy_header_reset_card_order",
            value: "Reset dashboard",
        },
        {
            key: "amaisy_header_user_details",
            value: "User settings",
        },
    ];
    var concatinatedTranslations = translations.concat(
        commonTranslation(),
        adminTranslation(),
        officeTranslation(),
        officeTooltipTranslation(),
        officeToastTranslation(),
        officeValidationTranslation(),
        officeLabelsTranslation()
    );
    return concatinatedTranslations;
}
