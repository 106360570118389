// import * as React from "react";
import { observer } from "mobx-react";
import { useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useRef } from "react";
import { SplitButton } from "primereact/splitbutton";
import { Toast } from "primereact/toast";
const Splitbuttons: React.FC = observer(() => {
    const toast = useRef(null);
    const items = [
        {
            label: "Update",
            icon: "pi pi-refresh",
            command: () => {
                //@ts-ignore
                toast.current.show({ severity: "success", summary: "Updated", detail: "Data Updated" });
            },
        },
        {
            label: "Delete",
            icon: "pi pi-times",
            command: () => {
                //@ts-ignore
                toast.current.show({ severity: "success", summary: "Delete", detail: "Data Deleted" });
            },
        },
        {
            label: "React Website",
            icon: "pi pi-external-link",
            command: () => {
                window.location.href = "https://facebook.github.io/react/";
            },
        },
        {
            label: "Upload",
            icon: "pi pi-upload",
            command: () => {
                window.location.hash = "/fileupload";
            },
        },
    ];

    const save = () => {
        //@ts-ignore
        toast.current.show({ severity: "success", summary: "Success", detail: "Data Saved" });
    };
    return (
        <>
            <div className="p-3  bg-light ">
                <h2>Splitbuttons from Primereact</h2>
                <div className="row">
                    <div className="col-sm-6">
                        <Toast ref={toast}></Toast>
                        <SplitButton label="Save" icon="pi pi-plus" onClick={save} model={items}></SplitButton>
                    </div>

                    <div className="col-sm-6"></div>
                </div>
            </div>
        </>
    );
});

export default Splitbuttons;
