import { observer } from "mobx-react";
import React from "react";
import CardsForm from "./cardsform";
import CardsList from "./CardsList";
import CardsPresenter from "./presenter";


interface Properties {
    presenter: CardsPresenter;
}



const Cards: React.FC<Properties> = observer(({ presenter }) => {
    return (
        <>
            {presenter.selectedCard && <CardsForm key={presenter.selectedCard.id} presenter={presenter} />}
            <CardsList presenter={presenter} />
        </>
    )

});

export default Cards;

