import { Template, TemplateOutPut, TemplateUsers } from "../types/mixedtypes";
import reqwest from "shared/utils/request";
import { getActions } from "../actionregistry";
import { Result } from "../presenter";
import { runInAction } from "mobx";

export async function loadTemplates(): Promise<Template[]> {
    try {
        const result = await reqwest.ajax<Template[]>({
            url: config.SERVICE_URL + "/Admin/Dashboard/Templates",
            type: "json",
            // @ts-ignore
            withCredentials: true,
            crossOrigin: true,
            method: "GET",
        });
        return result;
    } catch (response: any) {
        if (response.status === 401) {
            const result: Result = await getActions()!.showLoginDialog();
            runInAction(() => {
                if (result == "ok") {
                    return loadTemplates();
                }
            });
        }
        return loadTemplates();
    }
}

interface templateCreateData {
    clientId: string;
    name: string;
}
export async function templateCreate(data: string): Promise<templateCreateData> {
    const actions = getActions();
    const clientid = actions?.controller.presenters.applicationPresenter.user!.selectedClient!;
    const temp: templateCreateData = { clientId: clientid, name: data };
    try {
        const result = await reqwest.ajax<templateCreateData>({
            url: config.SERVICE_URL + "/Admin/Dashboard/CreateTemplate",
            type: "json",
            // @ts-ignore
            withCredentials: true,
            crossOrigin: true,
            method: "POST",
            data: JSON.stringify(temp),
            contentType: "application/json",
        });
        return result;
    } catch (response: any) {
        if (response.status === 401) {
            const result: Result = await getActions()!.showLoginDialog();
            runInAction(() => {
                if (result == "ok") {
                    return templateCreate(data);
                }
            });
        }
    }
    return templateCreate(data);
}

export interface templateUpdateCardsData {
    templateId: string;
    cards: { id: string; sortOrder: number }[];
}

export async function templateUpdateCards(data: templateUpdateCardsData): Promise<templateUpdateCardsData | undefined> {
    try {
        const result = await reqwest.ajax<templateUpdateCardsData>({
            url: config.SERVICE_URL + "/Admin/Dashboard/UpdateTemplateCards",
            type: "json",
            // @ts-ignore
            withCredentials: true,
            crossOrigin: true,
            method: "POST",
            data: JSON.stringify(data),
            contentType: "application/json",
        });

        return result;
    } catch (response: any) {
        if (response.status === 401) {
            const result: Result = await getActions()!.showLoginDialog();
            runInAction(() => {
                if (result == "ok") {
                    return templateUpdateCards(data);
                }
            });
        }
    }
    return data;
}

export async function loadTemplateUsers(templateId: string): Promise<TemplateUsers> {
    try {
        const result = await reqwest.ajax<TemplateUsers>({
            url: config.SERVICE_URL + "/Admin/Dashboard/TemplateUsers/" + templateId,
            type: "json",
            // @ts-ignore
            withCredentials: true,
            crossOrigin: true,
            method: "GET",
        });
        return result;
    } catch (response: any) {
        if (response.status === 401) {
            const result: Result = await getActions()!.showLoginDialog();
            runInAction(() => {
                if (result == "ok") {
                    return loadTemplateUsers(templateId);
                }
            });
        }
    }
    return loadTemplateUsers(templateId);
}

export interface templateUpdateUsersData {
    templateId: string;
    userIdsToAdd: string[];
    userIdsToRemove: string[];
}

export async function templateUpdateUsers(data: templateUpdateUsersData): Promise<templateUpdateUsersData> {
    try {
        const result = await reqwest.ajax<templateUpdateUsersData>({
            url: config.SERVICE_URL + "/Admin/Dashboard/UpdateTemplateUsers",
            type: "json",
            // @ts-ignore
            withCredentials: true,
            crossOrigin: true,
            method: "POST",
            data: JSON.stringify(data),
            contentType: "application/json",
        });
        return result;
    } catch (response: any) {
        if (response.status === 401) {
            const result: Result = await getActions()!.showLoginDialog();
            runInAction(() => {
                if (result == "ok") {
                    return templateUpdateUsers(data);
                }
            });
        }
    }
    return templateUpdateUsers(data);
}

export async function templateUpdate(template: Template): Promise<TemplateOutPut> {
    const data: TemplateOutPut = {
        templateId: template.id,
        name: template.templateName,
        isDefault: template.isDefault,
    };

    try {
        const result = await reqwest.ajax<TemplateOutPut>({
            url: config.SERVICE_URL + "/Admin/Dashboard/UpdateTemplate",
            type: "json",
            // @ts-ignore
            withCredentials: true,
            crossOrigin: true,
            method: "PUT",
            data: JSON.stringify(data),
            contentType: "application/json",
        });
        return result;
    } catch (response: any) {
        if (response.status === 401) {
            const result: Result = await getActions()!.showLoginDialog();
            runInAction(() => {
                if (result == "ok") {
                    return templateUpdate(template);
                }
            });
        }
    }
    return templateUpdate(template);
}
