import Actions from "./actions";

let a: Actions | undefined;

export function registerActions(actions: Actions) {
    a = actions;
}

export function getActions(): Actions | undefined {
    return a;
}
