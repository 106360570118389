import * as React from "react";
import { observer } from "mobx-react";
import { useState } from "react";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { addLocale } from "primereact/api";

const Calendars: React.FC = observer(() => {
    let today = new Date();
    let month = today.getMonth();
    let year = today.getFullYear();
    let prevMonth = month === 0 ? 11 : month - 1;
    let prevYear = prevMonth === 11 ? year - 1 : year;
    let nextMonth = month === 11 ? 0 : month + 1;
    let nextYear = nextMonth === 0 ? year + 1 : year;

    const [date1, setDate1] = useState<Date | Date[] | undefined>(undefined);
    const [date2, setDate2] = useState<Date | Date[] | undefined>(undefined);
    const [date3, setDate3] = useState<Date | Date[] | undefined>(undefined);
    const [date4, setDate4] = useState<Date | Date[] | undefined>(undefined);
    const [date5, setDate5] = useState<Date | Date[] | undefined>(undefined);
    const [date6, setDate6] = useState<Date | Date[] | undefined>(undefined);
    const [date7, setDate7] = useState<Date | Date[] | undefined>(undefined);
    const [date8, setDate8] = useState<Date | Date[] | undefined>(undefined);
    const [date9, setDate9] = useState<Date | Date[] | undefined>(undefined);
    const [date10, setDate10] = useState<Date | Date[] | undefined>(undefined);
    const [date11, setDate11] = useState<Date | Date[] | undefined>(undefined);
    const [date12, setDate12] = useState<Date | Date[] | undefined>(undefined);
    const [date13, setDate13] = useState<Date | Date[] | undefined>(undefined);
    const [date14, setDate14] = useState<Date | Date[] | undefined>(undefined);
    const [date15, setDate15] = useState<Date | Date[] | undefined>(undefined);
    const [date16, setDate16] = useState<Date | Date[] | undefined>(undefined);
    const [date17, setDate17] = useState<Date | Date[] | undefined>(undefined);
    const [dates1, setDates1] = useState<Date | Date[] | undefined>(undefined);
    const [dates2, setDates2] = useState<Date | Date[] | undefined>(undefined);
    const [visible, setVisible] = useState<boolean>(false);

    let minDate = new Date();
    minDate.setMonth(prevMonth);
    minDate.setFullYear(prevYear);

    let maxDate = new Date();
    maxDate.setMonth(nextMonth);
    maxDate.setFullYear(nextYear);

    let invalidDates = [today];

    addLocale("es", {
        firstDayOfWeek: 1,
        dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
        dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
        dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
        monthNames: [
            "enero",
            "febrero",
            "marzo",
            "abril",
            "mayo",
            "junio",
            "julio",
            "agosto",
            "septiembre",
            "octubre",
            "noviembre",
            "diciembre",
        ],
        monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
        today: "Hoy",
        clear: "Claro",
    });

    const dateTemplate = (date: any) => {
        if (date.day > 10 && date.day < 15) {
            return <strong style={{ textDecoration: "line-through" }}>{date.day}</strong>;
        }

        return date.day;
    };

    const monthNavigatorTemplate = (e: any) => {
        return (
            <Dropdown
                value={e.value}
                options={e.options}
                onChange={(event) => e.onChange(event.originalEvent, event.value)}
                style={{ lineHeight: 1 }}
            />
        );
    };

    const yearNavigatorTemplate = (e: any) => {
        return (
            <Dropdown
                value={e.value}
                options={e.options}
                onChange={(event) => e.onChange(event.originalEvent, event.value)}
                className="ml-2"
                style={{ lineHeight: 1 }}
            />
        );
    };

    const onVisibleChange = (e: any) => {
        setVisible(e.type === "dateselect" || !visible);
        if (e.callback) {
            e.callback();
        }
    };
    return (
        <>
            <div className="p-3 bg-light">
                <h2>Calendars</h2>
                <div className="row">
                    <div className="col-sm-4">
                        <div className="form-group">
                            <label htmlFor="calendarBasic" className="form-label d-block">
                                Basic
                            </label>
                            <Calendar id="calendarBasic" value={date1} onChange={(e) => setDate1(e.value)} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="calendarIcon" className="form-label d-block">
                                Icon
                            </label>
                            <Calendar id="calendarIcon" value={date2} onChange={(e) => setDate2(e.value)} showIcon />
                        </div>
                        <div className="form-group">
                            <label htmlFor="calendarSpanish" className="form-label d-block">
                                Spanish
                            </label>
                            <Calendar
                                id="calendarSpanish"
                                value={date3}
                                onChange={(e) => setDate3(e.value)}
                                locale="es"
                                dateFormat="dd/mm/yy"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="minmax" className="form-label d-block">
                                MinMax
                            </label>
                            <Calendar
                                id="minmax"
                                value={date4}
                                onChange={(e) => setDate4(e.value)}
                                minDate={minDate}
                                maxDate={maxDate}
                                readOnlyInput
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="disableddays" className="form-label d-block">
                                Disabled Days
                            </label>
                            <Calendar
                                id="disableddays"
                                value={date5}
                                onChange={(e) => setDate5(e.value)}
                                disabledDates={invalidDates}
                                disabledDays={[0, 6]}
                                readOnlyInput
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="navigators" className="form-label d-block">
                                Navigators
                            </label>
                            <Calendar
                                id="navigators"
                                value={date6}
                                onChange={(e) => setDate6(e.value)}
                                monthNavigator
                                yearNavigator
                                yearRange="2010:2030"
                            />
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="form-group">
                            <label htmlFor="multiple" className="form-label d-block">
                                Multiple
                            </label>
                            <Calendar
                                id="multiple"
                                value={dates1}
                                onChange={(e) => setDates1(e.value)}
                                selectionMode="multiple"
                                readOnlyInput
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="range" className="form-label d-block">
                                Range
                            </label>
                            <Calendar
                                id="range"
                                value={dates2}
                                onChange={(e) => setDates2(e.value)}
                                selectionMode="range"
                                readOnlyInput
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="buttonbar" className="form-label d-block">
                                Button Bar
                            </label>
                            <Calendar id="buttonbar" value={date7} onChange={(e) => setDate7(e.value)} showButtonBar />
                        </div>
                        <div className="form-group">
                            <label htmlFor="time24" className="form-label d-block">
                                Time / 24h
                            </label>
                            <Calendar
                                id="time24"
                                value={date8}
                                onChange={(e) => setDate8(e.value)}
                                showTime
                                showSeconds
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="monthpicker" className="form-label d-block">
                                Month Picker
                            </label>
                            <Calendar
                                id="monthpicker"
                                value={date10}
                                onChange={(e) => setDate10(e.value)}
                                view="month"
                                dateFormat="mm/yy"
                                yearNavigator
                                yearRange="2010:2030"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="multiplemonths" className="form-label d-block">
                                Multiple Months
                            </label>
                            <Calendar
                                id="multiplemonths"
                                value={date11}
                                onChange={(e) => setDate11(e.value)}
                                numberOfMonths={3}
                            />
                        </div>
                    </div>
                    <div className="col-sm-4">
                        {" "}
                        <div className="form-group">
                            <label htmlFor="datetemplate" className="form-label d-block">
                                Date Template
                            </label>
                            <Calendar
                                id="datetemplate"
                                value={date12}
                                onChange={(e) => setDate12(e.value)}
                                dateTemplate={dateTemplate}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="touchUI" className="form-label d-block">
                                TouchUI
                            </label>
                            <Calendar id="touchUI" value={date13} onChange={(e) => setDate13(e.value)} touchUI />
                        </div>
                        <div className="form-group">
                            <label htmlFor="mask" className="form-label d-block">
                                Mask
                            </label>
                            <Calendar id="mask" value={date14} onChange={(e) => setDate14(e.value)} mask="99/99/9999" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="navigatorstemplate" className="form-label d-block">
                                Navigators Template
                            </label>
                            <Calendar
                                id="navigatorstemplate"
                                value={date16}
                                onChange={(e) => setDate16(e.value)}
                                monthNavigator
                                yearNavigator
                                yearRange="2010:2030"
                                monthNavigatorTemplate={monthNavigatorTemplate}
                                yearNavigatorTemplate={yearNavigatorTemplate}
                            />
                        </div>
                        <h5>Inline</h5>
                        <Calendar value={date17} onChange={(e) => setDate17(e.value)} inline showWeek />
                    </div>
                </div>
            </div>
        </>
    );
});

export default Calendars;
