import { amaisyTranslation, Translation } from "./translations";
import reqwest from "../../request";

interface RequestTranslation {
    lang: string;
    translations: Translation[];
    reload: boolean;
    authorized: boolean;
}
export class Translater {
    translations: Translation[] = [];

    /**
     * Translate a specific text
     *
     * @param key - the key used for lookup in the translations array
     */
    translateText = (key: string): string => {
        const text = this.translations.find((x) => x.key == key);
        if (!text) {
            return `[${key}]`;
        }
        return text.value;
    };

    loadTranslations(langUrl: string, lang?: string, reload: boolean = false, authorized = true): Promise<void> {
        lang = lang ? lang : navigator.language;
        this.translations = amaisyTranslation();

        const translations: RequestTranslation = {
            lang: lang,
            translations: this.translations,
            reload: reload,
            authorized: authorized,
        };

        return new Promise(async (resolve, reject) => {
            try {
                const result = await reqwest.ajax<Translation[]>({
                    url: langUrl + "/Translation/Translations",
                    type: "json",
                    // @ts-ignore
                    withCredentials: true,
                    crossOrigin: true,
                    method: "POST",
                    data: JSON.stringify(translations),
                    contentType: "application/json",
                });
                //@ts-ignore
                this.translations = result.translations;
            } catch (response) {}

            resolve();
        });
    }
}

export default new Translater();
