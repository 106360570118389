import * as React from "react";
import { observer } from "mobx-react";
import { useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Badge } from "primereact/badge";
const Buttons: React.FC = observer(() => {
    return (
        <>
            <div className="p-3  bg-light ">
                <h2>Buttons from Primereact</h2>
                <div className="row">
                    <div className="col-sm-6">
                        <h5>Basic buttons</h5>
                        <p>free to use from now</p>

                        <Button label="Primary" className="p-button-primary" />
                        <Button label="Secondary" className="p-button-secondary " />
                        <Button label="Success" className="p-button-success " />
                        <Button label="Danger" className="p-button-danger " />
                        <Button label="Warning" className="p-button-warning " />
                        <Button label="Info" className="p-button-info " />
                        <Button label="Light" className="p-button-light" />
                        <Button label="Dark" className="p-button-dark " />

                        <Button label="Link" className="p-button-link " />
                        <Button label="Disabled" disabled={true} className="" />
                    </div>
                    <div className="col-sm-6">
                        <h5>Button sizes</h5>
                        <Button label="small button" className="p-button-primary p-button-sm" />
                        <Button label="Default button size" className="p-button-primary " />
                        <Button label="large button" className="p-button-secondary p-button-lg" />
                    </div>
                    <div className="col-sm-6">
                        <h5>Button with tooltip</h5>
                        <Button
                            tooltip="Can be added on all Primereact buttons :)"
                            label="Button with tooltip"
                            tooltipOptions={{ showDelay: 300, hideDelay: 300 }}
                            className="p-button-primary "
                        />
                    </div>
                    <div className="col-sm-6" style={{ display: "inline-block" }}>
                        <h5>Button with icons</h5>
                        <Button
                            icon="fa fa-bars fa-fw"
                            tooltip="add fontawesome icon into the button"
                            className="p-button-primary "
                        />
                        <br />
                        <Button
                            icon="fas fa-table fa-fw"
                            tooltip="add fontawesome icon into the button"
                            className="p-button-primary "
                        />
                        <br />
                        <Button
                            icon="fas fa-times fa-fw"
                            tooltip="add fontawesome icon into the button"
                            className="p-button-primary "
                        />
                        <Button
                            icon="fa fa-bars fa-3x"
                            tooltip="Large icon button"
                            className="p-button-primary"
                            // badgeClassName="fa-3x"
                        />
                        <Button
                            icon="fas fa-search-plus fa-3x"
                            tooltip="icon button with no background"
                            className="p-button-primary btn-only-icon"
                            // badgeClassName="fa-3x"
                        />
                        <Button
                            icon="fas fa-search-plus fa-3x"
                            tooltip="icon button with no background"
                            className="p-button-primary btn-only-icon"
                            // badgeClassName="fa-3x"
                            disabled
                        />
                        <Button
                            icon="fa fa-bars"
                            label="with icon"
                            tooltip="add fontawesome icon into the button"
                            className="p-button-secondary  "
                            iconPos="right"
                        />
                        <Button
                            icon="fa fa-bars"
                            label="with icon"
                            iconPos="left"
                            tooltip="add fontawesome icon into the button"
                            className="p-button-info  "
                        />
                    </div>

                    <div className="col-sm-6">
                        <h5>Button with Badges</h5>

                        <Button type="button" label="badge" className="me-2 p-button-info " tooltip="add badge">
                            <Badge value="8" severity="info"></Badge>
                        </Button>
                    </div>
                    <div className="col-sm-6 mt-3">
                        {/* <label htmlFor="fieldId">Input field sm</label> */}
                        <div className="mt-2">
                            <InputText
                                className="p-inputtext-sm"
                                id="fieldId"
                                tooltip="Insert text here"
                                // value={this.state.inputFieldValue}
                                placeholder="Insert text here!"
                                // onChange={(e) => {
                                //     return this.setState({
                                //         inputFieldValue: e.currentTarget.value,
                                //     });
                                // }}
                            />

                            <Button label="Primary" className="p-button-primary p-button-sm " />
                        </div>
                        <div className="mt-2">
                            <InputText
                                className=""
                                id="fieldId"
                                tooltip="Insert text here"
                                // value={this.state.inputFieldValue}
                                placeholder="Insert text here!"
                                // onChange={(e) => {
                                //     return this.setState({
                                //         inputFieldValue: e.currentTarget.value,
                                //     });
                                // }}
                            />

                            <Button label="Primary" className="p-button-primary " />
                        </div>
                        <div className="mt-2">
                            <InputText
                                className="p-inputtext-lg"
                                id="fieldId"
                                style={{ width: "100px" }}
                                tooltip="Insert text here"
                                // value={this.state.inputFieldValue}
                                placeholder="text here!"
                                // onChange={(e) => {
                                //     return this.setState({
                                //         inputFieldValue: e.currentTarget.value,
                                //     });
                                // }}
                            />

                            <Button label="Primary" className="p-button-primary p-button-lg" />
                        </div>

                        <div className="mt-2">
                            <InputText
                                className="p-inputtext-xl"
                                id="fieldId"
                                // style={{ width: "100px" }}
                                tooltip="Insert text here"
                                // value={this.state.inputFieldValue}
                                placeholder="text here!"
                                // onChange={(e) => {
                                //     return this.setState({
                                //         inputFieldValue: e.currentTarget.value,
                                //     });
                                // }}
                            />
                        </div>
                        <div className="mt-2">
                            <InputText
                                id="fieldIdReadOnly"
                                // style={{ width: "100px" }}
                                tooltip="Insert text here"
                                readOnly
                                // value={this.state.inputFieldValue}
                                placeholder="I am Readonly"
                                value="I am Readonly"
                                // onChange={(e) => {
                                //     return this.setState({
                                //         inputFieldValue: e.currentTarget.value,
                                //     });
                                // }}
                            />
                        </div>
                        <div className="mt-2">
                            <InputText
                                id="fieldIdDisabled"
                                // style={{ width: "100px" }}
                                tooltip="Insert text here"
                                value="I am Diasbled"
                                disabled
                                // value={this.state.inputFieldValue}
                                placeholder="I am disabled"
                                // onChange={(e) => {
                                //     return this.setState({
                                //         inputFieldValue: e.currentTarget.value,
                                //     });
                                // }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});

export default Buttons;
