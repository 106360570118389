import * as React from "react";
import { observer } from "mobx-react";

interface Properties {
    logo?: string;
}

const Footer: React.FC<Properties> = observer(({ logo }) => {
    const thisYear = new Date().getFullYear();

    return (
        <>
            <nav className="navbar navbar-expand-md navbar-dark fixed-bottom ">
                <ul className="navbar-nav fot-pad w-100">
                    <li className="nav-item mx-auto d-flex align-items-center">
                        <img src={logo} alt="SWECO" style={{ maxHeight: "30px", marginTop: "-15px" }} />
                        <i className="far fa-copyright ms-2"></i>
                        <span className="text-secondary ms-1"> {thisYear} SWECO AB</span>
                    </li>
                </ul>
            </nav>
        </>
    );
});

export default Footer;
