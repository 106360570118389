import React from "react";
import { observer } from "mobx-react";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import CardsPresenter from "./presenter";
import { DashboardCard } from "apps/amaisy/src/types/mixedtypes";
import Translater from "shared/utils/localizer";

interface Properties {
    presenter: CardsPresenter;
}

const CardsForm: React.FC<Properties> = observer(({ presenter }) => {
    const defaultValues: DashboardCard = {
        ...presenter.selectedCard!,
    };

    const {
        control,
        formState: { errors },
        setValue,
        handleSubmit,
        reset,
    } = useForm({ defaultValues });

    const onSubmit = (data: DashboardCard) => {
        presenter.update(data);
        reset();
        presenter.setSelectedCard(null);
    };

    const onReset = () => {
        reset();
        presenter.setSelectedCard(null);
    };

    const getFormErrorMessage = (name: keyof typeof errors) => {
        const error = errors[name];
        return error && <small className="p-error">{error.message}</small>;
    };

    const onFileLoad = (e: React.FormEvent<HTMLInputElement>) => {
        if (e.currentTarget.files) {
            const reader = new FileReader();
            const file = e.currentTarget.files[0];

            reader.addEventListener(
                "load",
                async () => {
                    const base64 = reader.result as string;

                    presenter.resizeImage(base64).then((result) => {
                        setValue("cardImage", result, { shouldDirty: true });
                    });
                },
                false
            );

            reader.readAsDataURL(file);
        }
    };

    const header = () => {
        return (
            <>
                <span>
                    {presenter.selectedCard?.id == ""
                        ? Translater.translateText("amaisy_admin_client_dialog_header_new_card")
                        : Translater.translateText("amaisy_admin_client_dialog_header_change_card")}
                </span>
            </>
        );
    };
    const renderFooter = () => {
        return (
            <>
                <Button
                    label={Translater.translateText("common_cancel")}
                    onClick={onReset}
                    className="p-button-text p-button-secondary"
                />
                <Button label={Translater.translateText("common_save")} className="p-button-primary" type="submit" />
            </>
        );
    };
    return (
        <>
            <Dialog
                header={header}
                visible={presenter.showDialog}
                onHide={() => presenter.toggleShowDialog()}
                className="p-dialog-sm"
                maximizable={false}
                resizable={true}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="content p-3 bg-light container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="cardName" className="form-label d-block">
                                        {Translater.translateText("amaisy_admin_card_dialog_input_name")}
                                    </label>
                                    <Controller
                                        name="cardName"
                                        control={control}
                                        rules={{
                                            required: Translater.translateText("common_required"),
                                        }}
                                        render={({ field, fieldState }) => (
                                            <InputText
                                                id={field.name}
                                                {...field}
                                                autoFocus
                                                className={fieldState.invalid ? "p-invalid" : ""}
                                            />
                                        )}
                                    />

                                    {getFormErrorMessage("cardName")}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="headerText" className="form-label d-block">
                                        {Translater.translateText("amaisy_admin_card_dialog_input_headline")}
                                    </label>
                                    <Controller
                                        name="headerText"
                                        control={control}
                                        rules={{
                                            required: Translater.translateText("common_required"),
                                        }}
                                        render={({ field, fieldState }) => (
                                            <InputText
                                                id={field.name}
                                                {...field}
                                                className={fieldState.invalid ? "p-invalid" : ""}
                                            />
                                        )}
                                    />
                                    {getFormErrorMessage("headerText")}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="description" className="form-label d-block">
                                        {" "}
                                        {Translater.translateText("amaisy_admin_card_dialog_input_description")}
                                    </label>
                                    <Controller
                                        name="description"
                                        control={control}
                                        rules={{
                                            required: Translater.translateText("common_required"),
                                        }}
                                        render={({ field, fieldState }) => (
                                            <InputText
                                                id={field.name}
                                                {...field}
                                                className={fieldState.invalid ? "p-invalid" : ""}
                                            />
                                        )}
                                    />

                                    {getFormErrorMessage("description")}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="linkText" className="form-label d-block">
                                        {" "}
                                        {Translater.translateText("amaisy_admin_card_dialog_input_link_name")}
                                    </label>
                                    <Controller
                                        name="linkText"
                                        control={control}
                                        rules={{
                                            required: Translater.translateText("common_required"),
                                        }}
                                        render={({ field, fieldState }) => (
                                            <InputText
                                                id={field.name}
                                                {...field}
                                                className={fieldState.invalid ? "p-invalid" : ""}
                                            />
                                        )}
                                    />

                                    {getFormErrorMessage("linkText")}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="linkUrl" className="form-label d-block">
                                        {Translater.translateText("amaisy_admin_card_dialog_input_link_url")}
                                    </label>
                                    <Controller
                                        name="linkUrl"
                                        control={control}
                                        rules={{
                                            required: Translater.translateText("common_required"),
                                        }}
                                        render={({ field, fieldState }) => (
                                            <InputText
                                                id={field.name}
                                                {...field}
                                                className={fieldState.invalid ? "p-invalid" : ""}
                                            />
                                        )}
                                    />

                                    {getFormErrorMessage("linkUrl")}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="cardImage" className="form-label d-block">
                                        {Translater.translateText("common_image")}
                                    </label>
                                    <Controller
                                        name="cardImage"
                                        control={control}
                                        rules={{
                                            required: Translater.translateText("common_required"),
                                        }}
                                        render={({ field, fieldState }) => (
                                            <>
                                                <img id={field.name} {...field} src={field.value} />

                                                <input
                                                    type="file"
                                                    onChange={onFileLoad}
                                                    className={fieldState.invalid ? "p-invalid" : "" + "file-upload"}
                                                />
                                            </>
                                        )}
                                    />

                                    {getFormErrorMessage("cardImage")}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="p-dialog-footer">{renderFooter()}</div>
                </form>
            </Dialog>
        </>
    );
});

export default CardsForm;
