import { Translation } from "./translations";

export function officeTranslation() {
    const translations: Translation[] = [
        {
            key: "amaisy_office_geometry_header_text",
            value: "Geometry",
        },
        {
            key: "amaisy_office_damagesection",
            value: "Damage section",
        },
        {
            key: "amaisy_office_activity_header_text",
            value: "Activity",
        },
        {
            key: "amaisy_office_history",
            value: "History",
        },
        {
            key: "amaisy_office_details_header_text",
            value: "Details",
        },
        {
            key: "amaisy_office_user_rating_header",
            value: "User rating",
        },
        {
            key: "amaisy_office_area",
            value: "Area",
        },
        {
            key: "amaisy_office_cost_header",
            value: "Cost",
        },
        {
            key: "amaisy_office_rating_header",
            value: "Rating",
        },
        {
            key: "amaisy_office_main_activity_header",
            value: "Main Activity",
        },
        {
            key: "amaisy_office_total_cost_header",
            value: "Total cost",
        },
        {
            key: "amaisy_office_product_name_header",
            value: "Product name",
        },
        {
            key: "amaisy_office_quantity_header",
            value: "Quantity",
        },
        {
            key: "amaisy_office_unit_header",
            value: "Unit",
        },
        {
            key: "amaisy_office_unit_price_header",
            value: "Unit price",
        },
        {
            key: "amaisy_office_remarks_header",
            value: "Remarks",
        },
        {
            key: "amaisy_office_edit_activity_header",
            value: "Edit Activity",
        },
        {
            key: "amaisy_office_no_main_activity",
            value: "No Main Activity",
        },
        {
            key: "amaisy_office_search_in_the_table",
            value: "Search in the table",
        },
        {
            key: "amaisy_office_add_new_activity_header",
            value: "Add a new Activity",
        },
        {
            key: "amaisy_office_loading",
            value: "Loading...",
        },
        {
            key: "amaisy_office_error_required",
            value: "This field is required",
        },
        {
            key: "amaisy_office_error_numbers_only",
            value: "This field most only contain numbers",
        },
        {
            key: "amaisy_office_error_min_3_letters",
            value: "This field most be atleast 3 letters",
        },
        {
            key: "amaisy_office_scenario_key",
            value: "Key",
        },
        {
            key: "amaisy_office_scenario_title",
            value: "Title",
        },
        {
            key: "amaisy_office_scenario_description",
            value: "Description",
        },
        {
            key: "amaisy_office_scenario_rating",
            value: "Rating",
        },
        {
            key: "amaisy_office_scenario_user_rating",
            value: "User Rating",
        },

        {
            key: "amaisy_office_column_workarea_included",
            value: "Included",
        },
        {
            key: "amaisy_office_activty_is_main_activity",
            value: "Main activity",
        },
        {
            key: "amaisy_office_activty_product_name",
            value: "Product",
        },
        {
            key: "amaisy_office_activty_product_quantity",
            value: "Quantity",
        },
        {
            key: "amaisy_office_activty_unit",
            value: "Unit",
        },
        {
            key: "amaisy_office_activty_unit_price",
            value: "Unit price",
        },
        {
            key: "amaisy_office_activty_cost",
            value: "Cost",
        },
        {
            key: "amaisy_office_activty_options",
            value: "Options",
        },
        {
            key: "amaisy_office_countworkareas",
            value: "Count work areas",
        },
        {
            key: "amaisy_office_scenarioitem",
            value: "Open scenarioitem",
        },
        {
            key: "amaisy_office_openworkareadetails",
            value: "Open detailed workarea",
        },
        {
            key: "amaisy_office_editactivity",
            value: "Edit activity",
        },
        {
            key: "amaisy_office_addnewactivity",
            value: "Add new activity",
        },

        {
            key: "amaisy_office_parentname",
            value: "Parent name",
        },
        {
            key: "amaisy_office_paginator_showfrom",
            value: "Showing from",
        },

        {
            key: "amaisy_office_paginator_to",
            value: "to",
        },

        {
            key: "amaisy_office_paginator_of",
            value: "of",
        },
        {
            key: "amaisy_office_from",
            value: "From",
        },
        {
            key: "amaisy_office_to",
            value: "To",
        },
        {
            key: "amaisy_office_Scenarios",
            value: "Scenarios",
        },
        {
            key: "amaisy_office_create_scenario",
            value: "Create scenario",
        },
        {
            key: "amaisy_office_create_workarea",
            value: "Create workarea",
        },
        {
            key: "amaisy_office_create_subscenario",
            value: "Create sub-scenario",
        },
        {
            key: "amaisy_office_create_new_workarea",
            value: "Create new workarea",
        },
        {
            key: "amaisy_office_copy_workarea",
            value: "Copy workarea(s)",
        },
        {
            key: "amaisy_office_delete_workarea",
            value: "Delete selected workarea(s)",
        },
        {
            key: "amaisy_office_workarea",
            value: "Workarea",
        },

        {
            key: "amaisy_office_onlyshowselectedworkareas",
            value: "Only show selected workareas(s)",
        },
        {
            key: "amaisy_office_showallworkareas",
            value: "Show all workareas",
        },
        {
            key: "amaisy_office_invertSelection",
            value: "Invert selection",
        },
        {
            key: "amaisy_office_clearSelection",
            value: "Clear selection",
        },

        {
            key: "amaisy_office_copyallsub-scenarios",
            value: "Copy all sub-scenarios",
        },
        {
            key: "amaisy_office_pastecopiedsub-scenarios",
            value: "Paste copied sub-scenarios",
        },
        {
            key: "amaisy_office_subscenario",
            value: "Sub-scenario",
        },
        {
            key: "amaisy_office_pasteworkarea",
            value: "Paste copied workareas",
        },
        {
            key: "amaisy_office_pasteworkareaconfirmtext",
            value: "You are now pasting workareas into matching sub-scenarios",
        },
        {
            key: "amaisy_office_clipboardwillbecleared",
            value: "Clipboard will be cleared after pasting.",
        },
        {
            key: "amaisy_office_clearclipboard",
            value: "Clipboard will be cleared after pasting.",
        },
        {
            key: "amaisy_office_archivescenario",
            value: "Archive scenario",
        },
        {
            key: "amaisy_office_renamescenario",
            value: "Rename scenario",
        },
        {
            key: "amaisy_office_exportscenario",
            value: "Export scenario",
        },

        {
            key: "amaisy_office_style",
            value: "Style",
        },
        {
            key: "amaisy_office_pickcolor",
            value: "Pick color",
        },
        {
            key: "amaisy_office_selectworkarea_placeholder",
            value: "Select workarea",
        },
        {
            key: "amaisy_office_style_activity",
            value: "Activity",
        },
        {
            key: "amaisy_office_style_product",
            value: "Product",
        },

        {
            key: "amaisy_office_copyworkarea",
            value: "Copy workarea",
        },
        {
            key: "amaisy_office_joinworkarea",
            value: "Join workarea",
        },

        {
            key: "amaisy_office_splitworkarea",
            value: "Split workarea",
        },
        {
            key: "amaisy_office_deleteworkarea",
            value: "Delete workarea",
        },
        {
            key: "amaisy_office_editgeometry",
            value: "Edit geometry",
        },
        {
            key: "amaisy_office_deletegeometry",
            value: "Delete geometry",
        },
        {
            key: "amaisy_office_deleteworkareaareyousure",
            value: "Are you sure you want to delete",
        },
        {
            key: "amaisy_office_deleteactivity",
            value: "Delete activity",
        },
        {
            key: "amaisy_office_deleteactivityareaareyousure",
            value: "Are you sure you want to delete activity?",
        },
        {
            key: "amaisy_office_title",
            value: "Title",
        },
        {
            key: "amaisy_office_affectedelements",
            value: "Affected elements",
        },
        {
            key: "amaisy_office_fromchainage",
            value: "From chainage",
        },
        {
            key: "amaisy_office_tochainage",
            value: "To chainage",
        },
        {
            key: "amaisy_office_elementtype",
            value: "Element type",
        },

        {
            key: "amaisy_office_alternativetitle",
            value: "Alternative title",
        },
        {
            key: "amaisy_office_description",
            value: "Description",
        },
        {
            key: "amaisy_office_toast_subscenariodeleted",
            value: "Scenario is deleted",
        },
        {
            key: "amaisy_office_rename_subscenario",
            value: "Rename Scenario",
        },
        {
            key: "amaisy_office_delete_subscenario",
            value: "Delete Scenario",
        },

        {
            key: "amaisy_office_joinworkarea_text1",
            value: "Your second selection will be included in your first selection.",
        },
        {
            key: "amaisy_office_joinworkarea_text2",
            value: "Activities will be merged together.",
        },
        {
            key: "amaisy_office_delete_scenario",
            value: "Delete Scenario",
        },
        {
            key: "amaisy_office_delete_scenarioConfirm",
            value: "Are you sure you want to delete the scenario",
        },
        {
            key: "amaisy_office_delete_subscenarioConfirm",
            value: "Are you sure you want to delete the subscenario and its workareas",
        },
        {
            key: "amaisy_office_rename_scenario",
            value: "Rename Scenario",
        },
        {
            key: "amaisy_office_delete_scenario_confirm",
            value: "You are about to delete a scenario including sub scenarions and workareas",
        },
        {
            key: "amaisy_office_totalcost",
            value: "totalCost",
        },
        {
            key: "amaisy_office_scenario_unit",
            value: "unit",
        },
        {
            key: "amaisy_office_scenario_productname",
            value: "productName",
        },
        {
            key: "amaisy_office_scenario_quantity",
            value: "quantity",
        },
        {
            key: "amaisy_office_workareas",
            value: "workarea(s)",
        },
        {
            key: "amaisy_office_workareas_into",
            value: "workareas into",
        },
        {
            key: "amaisy_office_selectWorkareaInTable",
            value: "Select a workarea in the table.",
        },
        {
            key: "amaisy_office_workareas_about_to",
            value: "You are now about to",
        },
        {
            key: "amaisy_office_copysubscenario",
            value: "Copy subscenario",
        },
        {
            key: "amaisy_office_pastesubscenario",
            value: "paste copied subscenario",
        },
        {
            key: "amaisy_office_ancillarycost_header",
            value: "Ancillary cost",
        },
        {
            key: "amaisy_office_dialog_select_workarea",
            value: "Select workarea",
        },
        {
            key: "amaisy_office_label_title_remaining",
            value: "Remaining title",
        },
        {
            key: "amaisy_office_label_title_dying",
            value: "Dying title",
        },
        {
            key: "amaisy_office_label_description_remaining",
            value: "Remaining description",
        },
        {
            key: "amaisy_office_label_description_dying",
            value: "Dying description",
        },
        {
            key: "amaisy_office_label_title",
            value: "Title",
        },
    ];
    return translations;
}
