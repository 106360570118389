import * as Reqwest from "reqwest";
import { pascalCase } from "pascal-case";

/**
 * Converts keys in object from camelCase to PascalCase
 *
 * Usefull for legacy serverside actions which require parameters
 * to be PascalCase, but typescript conventions require camelCase
 * @param obj - the object to convert
 */
export function convertDataCasing(obj: Record<string, any>) {
    const res: Record<string, any> = {};

    Object.keys(obj).forEach((key) => {
        res[pascalCase(key)] = obj[key];
    });

    return res;
}

export type Method = "GET" | "POST" | "PUT" | "DELETE";

export interface Options {
    url: string;
    method?: Method;
    headers?: object;
    data?: string | object;
    type?: string;
    contentType?: string;
    crossOrigin?: boolean;
    success?: (response: any) => void;
    error?: (error: any) => void;
    complete?: (response: any) => void;
    jsonpCallback?: string;
}

function ajax<T>(config: Options | string): Promise<T> {
    // tslint:disable-next-line: promise-must-complete - tslint cannot see that Reqwest resolves/rejects the promise
    const p = new Promise<T>((resolve, reject) => {
        if (typeof config === "string") {
            config = { url: config };
        }

        config.success = resolve;
        config.error = reject;

        Reqwest(config);
    });
    return p as any;
}

export default {
    ajax,
};
