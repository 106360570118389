import { IObservableArray, makeAutoObservable, observable, toJS } from "mobx";
import { ActiveMenuTypes } from "../../types/mixedtypes";
import CardsPresenter from "./cards/presenter";
import TemplatesPresenter from "./templates/presenter";
import Cards from "./cards";
import Templates from "./templates";
import React from "react";

interface MappedChosenComponent {
    component: JSX.Element;
    loader: () => void;
}

export class AdministrationPresenter {
    @observable mappedMenu = new Map<ActiveMenuTypes, boolean>();
    @observable mappedChosenComponents = new Map<ActiveMenuTypes, MappedChosenComponent>();
    cardsPresenter: CardsPresenter;
    templatePresenter: TemplatesPresenter;
    activeIndex: IObservableArray<number> = observable([]);
    constructor() {
        makeAutoObservable;
        this.cardsPresenter = new CardsPresenter();
        this.templatePresenter = new TemplatesPresenter();
        this.buildMappedMenu();
        this.buildMappedChosenComponents();
    }

    buildMappedMenu = () => {
        this.mappedMenu = new Map<ActiveMenuTypes, boolean>([
            ["SHOW ALL CARDS", false],
            ["SHOW CARDS TEMPLATE", false],
        ]);
    };

    buildMappedChosenComponents = () => {
        this.mappedChosenComponents = new Map<ActiveMenuTypes, MappedChosenComponent>([
            [
                "SHOW ALL CARDS",
                {
                    component: React.createElement(Cards, { presenter: this.cardsPresenter }),
                    loader: this.cardsPresenter.updateCards,
                },
            ],
            [
                "SHOW CARDS TEMPLATE",
                {
                    component: React.createElement(Templates, { presenter: this.templatePresenter }),
                    loader: this.templatePresenter.updateTemplates,
                },
            ],
        ]);
    };

    updateActive = (menuType: ActiveMenuTypes) => {
        for (let key of this.mappedMenu.keys()) {
            if (key === menuType) {
                this.mappedMenu.set(key, true);
                this.updateActiveInSession(key);
            } else {
                this.mappedMenu.set(key, false);
            }
        }
    };

    getIsActive = (menuType: ActiveMenuTypes) => {
        const expanded = this.mappedMenu.get(menuType);
        return expanded;
    };

    addActiveIndex = (index: number) => {
        const current: number[] = this.activeIndex;
        current.push(index);
        this.activeIndex.replace(current);
        this.updateActive(this.getActiveMenu()!);
    };

    removeFromActiveIndex = (index: number) => {
        const current: number[] = this.activeIndex;
        const newArr: number[] = current.filter((x) => x !== index);
        this.activeIndex.replace(newArr);
        this.updateActive(this.getActiveMenu()!);
    };

    replaceActiveIndex = (indexArr: number[]) => {
        this.activeIndex.replace(indexArr);
    };

    updateActiveInSession = (menuType: ActiveMenuTypes) => {
        const activeComponent = {
            index: toJS(this.activeIndex),
            comp: menuType,
        };
        sessionStorage.setItem("active", JSON.stringify(activeComponent));
    };

    getActiveMenu = () => {
        for (let [key, value] of this.mappedMenu) {
            if (value === true) return key;
        }
    };
}
