import * as React from "react";
import * as ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import Controller from "./controller";
import Actions from "./actions";
import { registerActions } from "./actionregistry";
import Translater from "shared/utils/localizer";
import Application from "./application";

const controller = new Controller();
const actions = new Actions(controller);
registerActions(actions);

const translationPromise = Translater.loadTranslations(config.SERVICE_URL, undefined, true, false);
const loadedLanguages = actions.setLanguages();
Promise.all([translationPromise, loadedLanguages]).then(() => {
    ReactDOM.render(
        <Router>
            <Application actions={actions} presenter={controller.presenters.applicationPresenter} />
        </Router>,
        document.getElementById("container")
    );
});
