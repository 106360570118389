import { IObservableArray, makeAutoObservable, observable, runInAction } from "mobx";
import { PortalClient, PortalUser } from "./types/userTypes";
import Translater from "shared/utils/localizer";
import { UserDetailPresenter } from "./component/userdetails/presenter";
import { getActions } from "./actionregistry";
import { UserAction, UserRole } from "shared/types/src/user";
import { Language } from "shared/components/header/src/types";
import { changeLanguage } from "./service/userservice";

export type Result = "ok" | "cancel";

export class ApplicationPresenter {
    user?: PortalUser = undefined;
    userActions: IObservableArray<UserAction> = observable([]);
    languages: IObservableArray<Language> = observable([]);
    currentLanguage: string = "";
    userRole?: UserRole;
    isAdmin: boolean = false;
    showLoginDialog: boolean = false;
    userDetailPresenter: UserDetailPresenter;
    emailConfirmed: boolean = false;
    selectedClient: string = "";

    private resolve?: (value: Result | PromiseLike<Result>) => void;

    constructor() {
        makeAutoObservable(this);
        this.userDetailPresenter = new UserDetailPresenter();
    }

    setUser = (user?: PortalUser) => {
        const fullUser: PortalUser = { ...user, ...this.user } as PortalUser;
        if (fullUser !== undefined && fullUser.role !== undefined) {
            this.setUserRole(fullUser!.role);
        }
        this.userDetailPresenter.setUser(fullUser);
        this.user = fullUser;
        const lang = this.user ? this.user.language : "";
        this.currentLanguage = lang;
        this.setSelectedClient();
    };

    setSelectedClient = () => {
        const clients: PortalClient[] = this.user!.clients;
        if (clients !== undefined) {
            getActions()!.setCards(clients.find((x) => x.id === this.user!.selectedClient)!.id!);
            this.selectedClient = clients.find((x) => x.id === this.user!.selectedClient)!.name;
        }
    };

    setUserActions = () => {
        const userActions: UserAction[] = [
            {
                action: "RESET CARDS ORDER",
                translatedAction: Translater.translateText("amaisy_header_reset_card_order"),
            } /* , WE NEED THIS LATER, DO NOT REMOVE
            { action: "USER DETALS", translatedAction: Translater.translateText("amaisy_header_user_details") }, */,
        ];
        this.userActions.replace(userActions);
    };

    setUserRole = (userRole: UserRole) => {
        this.userRole = userRole;
        this.isAdmin = this.getIsAdmin();
    };

    setLanguage = async (language: string) => {
        if (this.user == undefined) return;
        const changeLanguagePromise = await changeLanguage(language);
        runInAction(() => {
            if (changeLanguagePromise) {
                this.user!.language = changeLanguagePromise;
                const translationPromise = Translater.loadTranslations(config.SERVICE_URL, language, true);
                Promise.all([translationPromise]).then(() => {
                    runInAction(() => {
                        this.currentLanguage = this.user!.language;
                        this.userDetailPresenter.setUser(this.user!);
                    });
                });
            }
        });
    };

    clearUser = () => {
        this.user = undefined;
    };

    getIsAdmin = () => {
        return this.userRole === "Administrator" || this.userRole === "SiteAdministrator";
    };

    toggleShowLoginDialog = () => {
        this.showLoginDialog = !this.showLoginDialog;
    };

    showingLoginDialog = (): Promise<Result> => {
        this.showLoginDialog = !this.showLoginDialog;
        return new Promise((resolve) => {
            this.resolve = resolve;
        });
    };

    onOKClicked = () => {
        this.resolve!("ok");
    };

    setLanguages = async (languages: Language[]) => {
        this.languages.replace(languages);
        this.userDetailPresenter.setLanguagesLookup(languages);
    };

    setEmailConfirmed(confirmed: boolean) {
        this.emailConfirmed = confirmed;
    }
}
