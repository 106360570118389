import { Translation } from "./translations";

export function officeTooltipTranslation() {
    const translations: Translation[] = [
        {
            key: "amaisy_office_tooltip_close_scenariolist",
            value: "Close scenariolist",
        },
        {
            key: "amaisy_office_tooltip_open_scenariolist",
            value: "Open scenariolist",
        },
        {
            key: "amaisy_office_tooltip_close_attributetable",
            value: "Close attributetable",
        },
        {
            key: "amaisy_office_tooltip_open_attributetable",
            value: "Open attributetable",
        },
        {
            key: "amaisy_office_tooltip_open_menu",
            value: "Open attribute menu",
        },
        {
            key: "amaisy_office_tooltip_open_scenario_menu",
            value: "Open scenario menu",
        },
        {
            key: "amaisy_office_tooltip_create_card",
            value: "Create card",
        },

        {
            key: "amaisy_office_tooltip_create_scenario_menu",
            value: "Create scenario",
        },

        {
            key: "amaisy_office_tooltip_open_workarea_menu",
            value: "Open workarea menu",
        },
        {
            key: "amaisy_office_tooltip_addActivity",
            value: "Add new activity",
        },
        {
            key: "amaisy_office_tooltip_addnewtemplate",
            value: "Add new template",
        },
        {
            key: "amaisy_office_tooltip_markpolygon",
            value: "Mark polygon",
        },
        {
            key: "amaisy_office_tooltip_markarea",
            value: "Mark area",
        },
        {
            key: "amaisy_office_tooltip_select",
            value: "Select",
        },
        {
            key: "amaisy_office_tooltip_information",
            value: "Information",
        },

        {
            key: "amaisy_office_tooltip_opencontextmenufor",
            value: "Open contextmenu for",
        },
        {
            key: "amaisy_office_tooltip_areadescription",
            value: "This area is an on-the-fly calculation based on road width information in the RoSy system intersecting with the workarea polygon. Be aware this calculation will take information from all roads covered by a workarea into account. Therefore it might differ in some cases from the area calculated for activities in RoSy. A huge difference in area might indicate a data problem.",
        },
    ];
    return translations;
}
