import React, { useState, useRef } from "react";

import { observer } from "mobx-react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
const Toasts: React.FC = observer(() => {
    const toast = useRef(null);
    const toastTL = useRef(null);
    const toastBL = useRef(null);
    const toastBR = useRef(null);
    const toastBC = useRef(null);
    const toastCC = useRef(null);

    const showPrimary = () => {
        //@ts-ignore
        toast.current.show({
            severity: "primary",
            summary: "Primary",
            detail: "Message Content",
            life: 300000,
        });
    };
    const showSuccess = () => {
        //@ts-ignore
        toast.current.show({
            severity: "success",
            summary: "Success Message",
            detail: "Message Content",
            life: 300000,
        });
    };

    const showInfo = () => {
        //@ts-ignore

        toast.current.show({ severity: "info", summary: "Info Message", detail: "Message Content", life: 3000 });
    };

    const showWarn = () => {
        //@ts-ignore

        toast.current.show({ severity: "warn", summary: "Warn Message", detail: "Message Content", life: 3000 });
    };

    const showError = () => {
        //@ts-ignore

        toast.current.show({ severity: "error", summary: "Error Message", detail: "Message Content", life: 3000 });
    };

    const showTopLeft = () => {
        //@ts-ignore

        toastTL.current.show({ severity: "info", summary: "Info Message", detail: "Message Content", life: 3000 });
    };

    const showBottomLeft = () => {
        //@ts-ignore

        toastBL.current.show({ severity: "warn", summary: "Warn Message", detail: "Message Content", life: 3000 });
    };

    const showBottomRight = () => {
        //@ts-ignore

        toastBR.current.show({
            severity: "success",
            summary: "Success Message",
            detail: "Message Content",
            life: 3000,
        });
    };

    const showSticky = () => {
        //@ts-ignore

        toast.current.show({ severity: "info", summary: "Sticky Message", detail: "Message Content", sticky: true });
    };

    const showConfirm = () => {
        //@ts-ignore
        toastCC.current.show({
            severity: "primary",
            life: 300000,
            // sticky: true,
            content: (
                <>
                    <div className="p-toast-confirm-header">
                        <h5 className="p-toast-confirm-title">Modal title</h5>
                    </div>
                    <div className="p-toast-confirm-body">
                        <p>Modal body text goes here.</p>
                    </div>
                    <div className="p-toast-confirm-footer ">
                        <Button type="button" label="Cancel" className="p-button-secondary" onClick={close} />
                        <Button type="button" label="Save changes" className="p-button-success" />
                    </div>
                </>
            ),
        });
    };

    const showMultiple = () => {
        //@ts-ignore

        toast.current.show([
            { severity: "info", summary: "Message 1", detail: "Message 1 Content", life: 3000 },
            { severity: "info", summary: "Message 2", detail: "Message 2 Content", life: 3000 },
            { severity: "info", summary: "Message 3", detail: "Message 3 Content", life: 3000 },
        ]);
    };
    const close = () => {
        //@ts-ignore
        toastCC.current.clear();
    };
    const clear = () => {
        //@ts-ignore

        toast.current.clear();
    };

    return (
        <div className="p-3  bg-light ">
            <h2>Toasts/alets from Primereact</h2>

            <Toast ref={toast} />
            <Toast ref={toastTL} position="top-left" />
            <Toast ref={toastBL} position="bottom-left" />
            <Toast ref={toastBR} position="bottom-right" />
            <Toast ref={toastCC} position="center" className="p-toast-confirm-dialog" />

            <div className="">
                <h5>Severities</h5>
                <Button label="Primary" className="p-button-primary" onClick={showPrimary} />
                <Button label="Success" className="p-button-success" onClick={showSuccess} />

                <Button label="Info" className="p-button-info" onClick={showInfo} />
                <Button label="Warn" className="p-button-warning" onClick={showWarn} />
                <Button label="Error" className="p-button-danger" onClick={showError} />

                <h5>Positions</h5>
                <Button label="Top Left" className="mr-2" onClick={showTopLeft} />
                <Button label="Bottom Left" className="p-button-warning" onClick={showBottomLeft} />
                <Button label="Bottom Right" className="p-button-success" onClick={showBottomRight} />

                <h5>Options</h5>
                <Button onClick={showMultiple} label="Multiple" className="p-button-warning" />
                <Button onClick={showSticky} label="Sticky" />

                <h5>Clear</h5>
                <Button onClick={clear} label="Clear" />

                <h5>Customasdasd</h5>
                <Button type="button" onClick={showConfirm} label="Confirm 'Dialog'" className="" />
            </div>
        </div>
    );
});

export default Toasts;
