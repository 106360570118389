import * as React from "react";
import { observer } from "mobx-react";
import { useState } from "react";
// import Typography from "./../src";
import Typography from "./typography/src";
import Buttons from "./buttons/src";
import Badge from "./badge/src";
import Accordion from "./accordion/src";
import Checkbox from "./checkboxes/src";
import Radiobutton from "./radiobuttons/src";
import Dropdowns from "./dropdowns/src";
import Inputs from "./inputs/src";
import Datatables from "./datatable/src";
import Password from "./password/src";
import Calendars from "./calendar/src";
import Tabview from "./tabview/src";
import Toasts from "./toasts/src";
//import Speeddial from "./speeddial/src";
import Splitbuttons from "./splitbutton/src";
//import Spinners from "./spinners/src";

// import LoginAsDialog from "./component/login/dialog";

const ProtoType: React.FC = observer(({}) => {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-sm-4">
                        <Typography />
                    </div>
                    <div className="col-sm-8">
                        <Buttons />
                        <Splitbuttons />
                    </div>
                </div>
                <div className="row mt-2"></div>
                <div className="row mt-2">
                    <div className="col-sm-4">
                        <Badge />
                    </div>
                    <div className="col-sm-8">
                        <div className="row">
                            <div className="col-sm-6">
                                <Checkbox />
                            </div>
                            <div className="col-sm-6">
                                <Radiobutton />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-sm-4">
                        <Accordion />
                    </div>
                    <div className="col-sm-4">
                        <Dropdowns />
                        <div className="mt-2">
                            <Password />
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <Inputs />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4">{/*  <Spinners /> */}</div>
                </div>
                <div className="row">
                    <div className="col-sm-4">
                        <Toasts />
                    </div>
                    <div className="col-sm-8">{/*  <Speeddial /> */}</div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <Tabview />
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <Calendars />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12">
                        <Datatables />
                    </div>
                </div>
            </div>
        </>
    );
});

export default ProtoType;
