import { ApplicationPresenter } from "./presenter";
import { BoardPresenter } from "./component/dashboard/presenter";
import { AdministrationPresenter } from "./component/administration/presenter";

interface Presenters {
    boardPresenter: BoardPresenter;
    applicationPresenter: ApplicationPresenter;
    administrationPresenter: AdministrationPresenter;
}

export default class Controller {
    presenters: Presenters = {
        boardPresenter: new BoardPresenter(),
        applicationPresenter: new ApplicationPresenter(),
        administrationPresenter: new AdministrationPresenter(),
    };
}
