import { observer } from "mobx-react";
import React from "react";
import TemplatesPresenter, { UserWithInclude } from "./presenter";
import { toJS } from "mobx";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import Translater from "shared/utils/localizer";

interface Properties {
    presenter: TemplatesPresenter;
}

const TemplateUsers: React.FC<Properties> = observer(({ presenter }) => {
    const onButtonSaveClick = () => {
        presenter.saveUsers();
    };

    const includeButtonClicked = (data: UserWithInclude) => {
        presenter.setIncludeUser(data);
    };
    const excludeButtonClicked = (data: UserWithInclude) => {
        presenter.setExcludeUser(data);
    };
    const buttonBodyTemplate = (data: UserWithInclude) => {
        if (data.included) {
            return (
                <div className="text-center">
                    <Button
                        icon="fas fa-user-check"
                        className="p-button-success"
                        tooltip={Translater.translateText("amaisy_admin_template_table_user_tooltip_remove_access")}
                        tooltipOptions={{ position: "left" }}
                        onClick={() => excludeButtonClicked(data)}
                    ></Button>
                </div>
            );
        } else {
            return (
                <div className="text-center">
                    <Button
                        icon="fas fa-user-slash"
                        className="p-button-danger"
                        tooltip={Translater.translateText("amaisy_admin_template_table_user_tooltip_add_access")}
                        tooltipOptions={{ position: "left" }}
                        onClick={() => includeButtonClicked(data)}
                    ></Button>
                </div>
            );
        }
    };

    const userItems = toJS(presenter.users);
    return (
        <>
            <div className="datatable-responsive-demo p-3 pb-5">
                <DataTable
                    value={userItems}
                    sortField={"included"}
                    sortOrder={-1}
                    className="p-datatable-responsive-demo"
                >
                    <Column
                        field="user.name"
                        header={Translater.translateText("amaisy_admin_user_table_column_name")}
                        filter={true}
                    />
                    <Column
                        field="user.email"
                        header={Translater.translateText("amaisy_admin_user_table_column_email")}
                        filter={true}
                    />
                    <Column field="included" body={buttonBodyTemplate} sortable={true} />
                </DataTable>{" "}
            </div>
        </>
    );
});

export default TemplateUsers;
