import { Translation } from "./translations";

export function officeToastTranslation() {
    const translations: Translation[] = [
        {
            key: "amaisy_office_toast_newscenariocreated",
            value: "New scenario created",
        },
        {
            key: "amaisy_office_toast_scenario",
            value: "Scenario",
        },
        {
            key: "amaisy_office_toast_newclientcreated",
            value: "New client is created",
        },
        {
            key: "amaisy_office_toast_stylesaresaved",
            value: "Styles are saved",
        },
        {
            key: "amaisy_office_toast_changesaresavedon",
            value: "Changes are saved on",
        },
        {
            key: "amaisy_office_toast_newworkareacreated",
            value: "New workarea created",
        },

        {
            key: "amaisy_office_toast_newsubscenariocreated",
            value: "New sub-scenario created",
        },
        {
            key: "amaisy_office_toast_sub-scenarioscopied",
            value: "Sub-scenarios copied",
        },
        {
            key: "amaisy_office_toast_workareapasted",
            value: "Workarea pasted",
        },
        {
            key: "amaisy_office_toast_workareacopied",
            value: "Workarea copied",
        },
        {
            key: "amaisy_office_toast_workarea",
            value: "Workarea",
        },
        {
            key: "amaisy_office_toast_copied",
            value: "copied",
        },

        {
            key: "amaisy_office_toast_workarecreated",
            value: "Workarea created",
        },

        {
            key: "amaisy_office_toast_workareajoined",
            value: "Workarea joined",
        },
        {
            key: "amaisy_office_toast_workareaselected",
            value: "Workarea selected",
        },
        {
            key: "amaisy_office_toast_workareasplitted",
            value: "Workarea splitted",
        },

        {
            key: "amaisy_office_toast_headersuccess",
            value: "Success!",
        },
        {
            key: "amaisy_office_toast_workareadeleted",
            value: "Workarea deleted",
        },
        {
            key: "amaisy_office_toast_activitydeleted",
            value: "Activity deleted",
        },
        {
            key: "amaisy_office_toast_renamesubscenario",
            value: "Renamed subscenario",
        },
        {
            key: "amaisy_office_toast_geomtrydeleted",
            value: "Geometry deleted",
        },

        {
            key: "amaisy_office_toast_onlyselectedworkareasshown",
            value: "Only selected workareas(s) shown",
        },
        {
            key: "amaisy_office_toast_selectioninverted",
            value: "Selection inverted",
        },
        {
            key: "amaisy_office_toast_selectioncleared",
            value: "Selection cleared",
        },

        {
            key: "amaisy_office_toast_copyallsub-scenarios",
            value: "All Sub scenarios is copied to clipboard",
        },
        {
            key: "amaisy_office_toast_pasteallsub-scenarios",
            value: "All Sub scenarios are pasted",
        },
        {
            key: "amaisy_office_toast_error_title",
            value: "Error",
        },
        {
            key: "amaisy_office_toast_success_title",
            value: "Success",
        },
        {
            key: "amaisy_office_toast_headerrejected",
            value: "Rejected!",
        },
        {
            key: "amaisy_office_toast_actioncanceled",
            value: "Action cancelled.",
        },
        {
            key: "amaisy_office_toast_activityedited",
            value: "Activity edited.",
        },
        {
            key: "amaisy_office_toast_cant_copy_to_same_parent",
            value: "You can not copy to same scenario",
        },
        {
            key: "amaisy_office_toast_renamescenario",
            value: "Renamed scenario",
        },
        {
            key: "amaisy_office_toast_isdeleted",
            value: "is deleted",
        },
        {
            key: "amaisy_office_toast_iscreated",
            value: "is created",
        },
        {
            key: "amaisy_office_toast_isupdated",
            value: "is updated",
        },
        {
            key: "amaisy_office_toast_from",
            value: "from",
        },
        {
            key: "amaisy_office_toast_to",
            value: "to",
        },
        {
            key: "amaisy_office_toast_copy_success",
            value: "You successfully",
        },
        {
            key: "amaisy_office_toast_error",
            value: "Something went wrong",
        },
        {
            key: "amaisy_office_toast_subscenariocopied",
            value: "Subscenario copied",
        },
        {
            key: "amaisy_office_toas_subscenariopasted",
            value: "Subscenario pasted successfully",
        },
        {
            key: "amaisy_office_toast_workareas_selected_in_map",
            value: "workareas selected in map",
        },
        {
            key: "amaisy_office_export_toExcel",
            value: "exported excelfile will be downloaded",
        },
        {
            key: "amaisy_office_export_toExcel_title",
            value: "Export to excel file",
        },
        {
            key: "amaisy_office_toast_splitnotpossible",
            value: "It is not possible to split the workarea according to the drawn line. Please try again",
        },
        {
            key: "amaisy_office_toast_splitfailed",
            value: "Split of workarea failed",
        },
        {
            key: "amaisy_office_toast_edit_geometry_invalid",
            value: "The geometry is invalid",
        },
        {
            key: "amaisy_office_toast_newpasswordcreated",
            value: "Password successfully reset",
        },
        {
            key: "amaisy_office_toast_passwordnotidentical",
            value: "The two passwords are not identical",
        },
        {
            key: "amaisy_office_toast_newpassworderror",
            value: "An error occured. Your password has not been changed",
        },
    ];
    return translations;
}
