import { toJS } from "mobx";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getActions } from "../../actionregistry";
import { DashboardCard } from "../../types/mixedtypes";
import CardItem from "./card";
import { BoardPresenter } from "./presenter";

interface Properties {
    presenter: BoardPresenter;
    newUser: boolean;
}

const Board: React.FC<Properties> = observer(({ presenter, newUser }) => {
    const [dragId, setDragId] = useState();
    const cards = toJS(presenter.dashboardCards);
    let navigate = useNavigate();

    useEffect(() => {
        if (newUser) {
            getActions()!.showUserDetails();
            navigate("/", { replace: true });
        }
    });

    const handleDrag = (evt: any) => {
        setDragId(evt.currentTarget.id);
    };

    const handleDrop = (evt: any) => {
        const dragCard = cards.find((card: DashboardCard) => card.id === dragId);
        const dropCard = cards.find((card: DashboardCard) => card.id === evt.currentTarget.id);

        const dragBoxOrder = dragCard!.sortOrder;
        const dropBoxOrder = dropCard!.sortOrder;

        const newCardsState = cards.map((card) => {
            if (card.id === dragId) {
                card.sortOrder = dropBoxOrder;
            }
            if (card.id === evt.currentTarget.id) {
                card.sortOrder = dragBoxOrder;
            }
            return card;
        });

        presenter.setCards(newCardsState);
    };

    return (
        <>
            <div className="container-xxl" key={"fo"}>
                <div className="row " key={"bla"}>
                    {cards.length === 0 ? (
                        <>Ingen adgang - Kontakt administrator</>
                    ) : (
                        cards
                            .sort((a, b) => a.sortOrder - b.sortOrder)
                            .map((cardItem, index) => {
                                return (
                                    <CardItem
                                        card={cardItem}
                                        key={index}
                                        cardNumber={cardItem.id}
                                        handleDrag={handleDrag}
                                        handleDrop={handleDrop}
                                        user={presenter.getCurrentUser()!}
                                    />
                                );
                            })
                    )}
                </div>
            </div>
        </>
    );
});

export default Board;
