import { observer } from "mobx-react";
import { toJS } from "mobx";
import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import CardsPresenter from "./presenter";
import { DashboardCard } from "apps/amaisy/src/types/mixedtypes";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import Translater from "shared/utils/localizer";

interface Properties {
    presenter: CardsPresenter;
}

const CardsList: React.FC<Properties> = observer(({ presenter }) => {
    const onRowClick = (e: { originalEvent: Event; data: DashboardCard; index: number }) => {
        presenter.setSelectedCard(e.data);
    };

    const onEditClick = (card: DashboardCard) => {
        presenter.setSelectedCard(card);
    };

    const onDeleteClick = (card: DashboardCard) => {
        confirmDialog({
            message: `${Translater.translateText("amaisy_admin_delete_confirm_dialog_message")}  ${card.cardName}?`,
            header: Translater.translateText("amaisy_admin_delete_confirm_dialog_header"),
            icon: "pi pi-exclamation-triangle",
            rejectLabel: Translater.translateText("common_cancel"),
            acceptLabel: Translater.translateText("common_delete"),
            maximizable: false,
            resizable: false,
            accept: () => {
                presenter.deleteCard(card);
            },
        });
    };

    const onCreateClick = () => {
        presenter.initiateCreateCard();
    };

    const imageBodyTemplate = (data: DashboardCard) => {
        return <img src={data.cardImage} className="product-image3 " style={{ maxHeight: "75px" }} />;
    };
    const actionBodyTemplate = (data: DashboardCard) => {
        return (
            <>
                <div className="text-center ">
                    <div className="d-flex">
                        <Button
                            icon="fa fa-pen"
                            className=" p-button-success me-2"
                            onClick={() => onEditClick(data)}
                            tooltip={Translater.translateText("amaisy_admin_card_table_edit_tooltip")}
                            tooltipOptions={{ position: "left" }}
                        />
                        <Button
                            icon="fa fa-trash"
                            tooltip={Translater.translateText("amaisy_admin_card_table_delete_tooltip")}
                            className="p-button-danger"
                            tooltipOptions={{ position: "left" }}
                            onClick={() => onDeleteClick(data)}
                        />
                    </div>
                </div>
            </>
        );
    };

    const foo = toJS(presenter.cards);
    return (
        <>
            <div className="text-end mt-2 mb-2">
                <Button
                    label={Translater.translateText("common_create")}
                    icon="fas fa-plus"
                    className="p-button-primary"
                    iconPos="right"
                    tooltip={Translater.translateText("amaisy_office_tooltip_create_card")}
                    tooltipOptions={{ position: "left" }}
                    data-translate_key={Translater.translateText("common_create")}
                    onClick={onCreateClick}
                    aria-haspopup
                />
            </div>
            <div className="datatable-responsive-demo p-3 pb-5">
                <DataTable value={toJS(presenter.cards)} className="p-datatable-responsive-demo">
                    <Column
                        field="cardName"
                        header={Translater.translateText("amaisy_admin_card_table_column_name")}
                        filter={true}
                    />
                    <Column
                        field="headerText"
                        header={Translater.translateText("amaisy_admin_card_table_column_headline")}
                        filter={true}
                    />
                    <Column
                        field="description"
                        header={Translater.translateText("amaisy_admin_card_table_column_description")}
                        filter={true}
                    />
                    <Column
                        field="linkText"
                        header={Translater.translateText("amaisy_admin_card_table_column_link_name")}
                        filter={true}
                    />
                    <Column
                        field="linkUrl"
                        header={Translater.translateText("amaisy_admin_card_table_column_link_url")}
                        filter={true}
                    />
                    <Column
                        field="cardImage"
                        body={imageBodyTemplate}
                        header={Translater.translateText("common_image")}
                    />
                    <Column body={actionBodyTemplate}></Column>
                </DataTable>
            </div>
        </>
    );
});

export default CardsList;
