import { runInAction } from "mobx";
import Controller from "./controller";
import { loadDashBoardCards } from "./service/usercardsload";
import { DashboardCard } from "./types/mixedtypes";
import { PortalUser, PortalClient } from "./types/userTypes";
import { changeUserClient, loadUserWithData, checkUserIsLoggedIn, getLanguages } from "./service/userservice";
import { changePassword, updateUser } from "./service/users";
import { ChangePasswordResult, UserRole } from "shared/types/src/user";
import { Language } from "shared/components/header/src/types";

export default class Actions {
    controller: Controller;
    constructor(controller: Controller) {
        this.controller = controller;
    }

    authUserById = async (): Promise<boolean> => {
        let currentUserId = await checkUserIsLoggedIn();
        if (currentUserId !== null) {
            const sessionUserId: string = currentUserId;
            const user: PortalUser | undefined = await this.loadUserWithData(sessionUserId);
            runInAction(() => {
                if (user !== undefined) {
                    this.controller.presenters.applicationPresenter.setUser(user);
                    return true;
                }
                return false;
            });
        }
        return this.controller.presenters.applicationPresenter.user != undefined;
    };

    setLanguages = async (): Promise<boolean> => {
        const languages: Language[] | [] = await getLanguages();
        runInAction(() => {
            this.controller.presenters.applicationPresenter.setLanguages(languages);
            this.controller.presenters.applicationPresenter.setUserActions();
            return true;
        });
        return true;
    };

    changePassword = async (currentPassword: string, newPassword: string): Promise<ChangePasswordResult> => {
        return await changePassword(currentPassword, newPassword);
    };

    updateUser = async (
        name: string,
        phoneNumber: string,
        mobileNumber: string,
        language: string,
        email: string
    ): Promise<PortalUser> => {
        const user: PortalUser = await updateUser(name, phoneNumber, mobileNumber, language, email).then((user) => {
            this.controller.presenters.applicationPresenter.setUser(user);
            this.controller.presenters.applicationPresenter.userDetailPresenter.setUser(user);
            return user;
        });
        this.controller.presenters.applicationPresenter.setLanguage(user.language);
        return user as PortalUser;
    };

    showUserDetails() {
        this.controller.presenters.applicationPresenter.userDetailPresenter.setUserDetailDialogVisible(true);
    }

    setCards = async (clientId: string) => {
        let cards: DashboardCard[] = await loadDashBoardCards(clientId);
        if (cards !== undefined) {
            cards = cards as DashboardCard[];
            if (cards.length > 0) {
                this.controller.presenters.boardPresenter.showCards(cards as DashboardCard[], clientId);
            } else {
                this.controller.presenters.boardPresenter.showCards([], clientId);
            }
        }
    };

    loadUserWithData = async (userId: string) => {
        const userWithData: PortalUser | undefined = await loadUserWithData(userId);
        return userWithData;
    };

    saveClient = async (client: PortalClient) => {
        const clients: PortalClient[] = this.controller.presenters.applicationPresenter.user!.clients;
        clients.push(client);
        return clients;
    };

    changeUserClient = async (clientId: string) => {
        const role: UserRole | undefined = await changeUserClient(clientId);
        if (role !== undefined) {
            this.controller.presenters.applicationPresenter.setUserRole(role as UserRole);
            this.setCards(clientId);
        }
    };

    getCurrentUser = () => {
        return this.controller.presenters.applicationPresenter.user;
    };

    showLoginDialog = async () => {
        return await this.controller.presenters.applicationPresenter.showingLoginDialog();
    };
}
