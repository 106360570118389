import { PortalClient, PortalUser } from "../types/userTypes";
import reqwest from "shared/utils/request";
import { runInAction } from "mobx";
import { loadAllClients } from "./clients";
import { Result } from "../presenter";
import { getActions } from "../actionregistry";

import { LoginCredentials, Role, SelectedUserLang, UserRole } from "shared/types/src/user";
import { Language } from "shared/components/header/src/types";

export async function loadUserWithData(userId: string): Promise<PortalUser | undefined> {
    try {
        const result = await reqwest.ajax<PortalUser>({
            url: config.SERVICE_URL + "/User/User/" + userId,
            type: "json",
            // @ts-ignore
            withCredentials: true,
            crossOrigin: true,
            method: "GET",
        });

        if (result.role === "SiteAdministrator") {
            const clients: PortalClient[] | number = await loadAllClients();
            runInAction(() => {
                result.clients = clients as PortalClient[];
            });
        }
        return result;
    } catch (response: any) {
        if (response.status === 401) {
            const result: Result = await getActions()!.showLoginDialog();
            runInAction(() => {
                if (result == "ok") {
                    return loadUserWithData(userId);
                }
            });
        }
    }
    return undefined;
}

export async function changeUserClient(clientId: string): Promise<UserRole | undefined> {
    try {
        const result = await reqwest.ajax<Role>({
            url: config.SERVICE_URL + "/user/changeselectedclient?clientId=" + clientId,
            type: "json",
            method: "PUT",
            contentType: "application/json",
            // @ts-ignore
            withCredentials: true,
        });
        return result.role;
    } catch (response: any) {
        if (response.status === 401) {
            const result: Result = await getActions()!.showLoginDialog();
            runInAction(() => {
                if (result == "ok") {
                    return changeUserClient(clientId);
                }
            });
        }
    }
    return undefined;
}

export async function checkUserIsLoggedIn(): Promise<string | null> {
    return reqwest
        .ajax<string | null>({
            url: config.SERVICE_URL + "/Account/GetLoggedInUser",
            method: "GET",
            contentType: "application/json",
            headers: {
                accept: ["text/plain"],
            },
            // @ts-ignore
            withCredentials: true,
        })
        .then((data) => {
            if (typeof data == "string") {
                return data;
            } else {
                return null;
            }
        });
}

export async function changeLanguage(language: string): Promise<string> {
    try {
        const selectedUserLang = await reqwest.ajax<SelectedUserLang>({
            url: config.SERVICE_URL + `/user/changelanguage?language=` + language,
            type: "json",
            method: "PUT",
            contentType: "application/json",
            // @ts-ignore
            withCredentials: true,
        });
        return selectedUserLang.code;
    } catch (response: any) {
        return "en";
    }
}

export async function getLanguages(): Promise<Language[]> {
    try {
        return reqwest
            .ajax<Language[]>({
                url: config.SERVICE_URL + "/User/Languages",
                method: "GET",
                contentType: "application/json",
                headers: {
                    accept: ["text/plain"],
                },
                // @ts-ignore
                withCredentials: true,
            })
            .then((result) => {
                return result;
            });
    } catch (response: any) {
        return [];
    }
}

export async function getLoginUrl(deeplink: string, parameters: string): Promise<string> {
    try {
        return reqwest
            .ajax<string>({
                url:
                    config.SERVICE_URL +
                    "/Account/LoginUrl?deeplink=" +
                    deeplink +
                    "&" +
                    parameters.substring(1, parameters.length),
                method: "GET",
                contentType: "application/json",
                headers: {
                    accept: ["text/plain"],
                },
                // @ts-ignore
                withCredentials: true,
            })
            .then((result) => {
                return result;
            });
    } catch (response: any) {}
    return "undefined";
}
