import { Translation } from "./translations";

export function officeValidationTranslation() {
    const translations: Translation[] = [
        {
            key: "amaisy_office_validatetext_error",
            value: "Atleast 3 chars is needed.",
        },
        {
            key: "amaisy_office_validatetext_error_min2char",
            value: "Minimun 2 characters for the name is required!",
        },
        {
            key: "amaisy_office_validatetext_error_min2chardescription",
            value: "Minimun 2 characters for the description is required!",
        },
        {
            key: "amaisy_office_validatetext_error_newscenariorequired",
            value: "A new for scenario is required!",
        },
        {
            key: "amaisy_office_validatetext_error_selectionrequired",
            value: "Selection here is required",
        },

        {
            key: "amaisy_office_validatetext_error_mustbenumber",
            value: "It must be a number!",
        },
        {
            key: "amaisy_office_validatetext_error_mustbegreaterthan0",
            value: "It must be greater than zero!",
        },
        {
            key: "amaisy_office_validatetext_error_numberofquantityrequired",
            value: "A number of quantity is required",
        },
        {
            key: "amaisy_office_validatetext_error_numberofdescriptionrequired",
            value: "A description is required!",
        },
    ];
    return translations;
}
