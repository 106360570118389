import * as React from "react";
import { observer } from "mobx-react";
import { useState } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { InputText } from "primereact/inputtext";
const Buttons: React.FC = observer(() => {
    return (
        <div className="p-3  bg-light">
            <h2>Accordion</h2>
            <Accordion
            // activeIndex={this.state.activeIndex}
            // onTabChange={(e) => this.setState({ activeIndex: e.index })}
            >
                <AccordionTab header="Header I">Content I</AccordionTab>
                <AccordionTab header="Header II">Content II</AccordionTab>
                <AccordionTab header="Header III">Content III</AccordionTab>
            </Accordion>
        </div>
    );
});

export default Buttons;
