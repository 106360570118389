import { observable, IObservableArray, makeAutoObservable, toJS } from "mobx";
import { CardOrder, DashboardCard } from "../../../../amaisy/src/types/mixedtypes";
import { getActions } from "../../actionregistry";

export class BoardPresenter {
    dashboardCards: IObservableArray<DashboardCard> = observable([]);
    currentClientId?: string;
    constructor() {
        makeAutoObservable(this);
    }

    showCards = (cards: DashboardCard[], clientId: string) => {
        this.currentClientId = clientId;
        cards.map((card, index) => {
            if (localStorage.getItem(card.id) !== null) {
                const cardOrder: CardOrder = JSON.parse(localStorage.getItem(card.id)!);
                if (cardOrder.clientId === clientId) {
                    card.sortOrder = parseInt(cardOrder.sortOrder);
                }
            } else {
                const cardOrder: CardOrder = {
                    id: card.id,
                    sortOrder: index.toString(),
                    clientId: clientId,
                };
                localStorage.setItem(card.id, JSON.stringify(cardOrder));
                card.sortOrder = index;
            }
        });
        this.dashboardCards.replace(cards);
    };

    setCards = (cards: DashboardCard[]) => {
        cards.map((card) => {
            const cardOrder: CardOrder = {
                id: card.id,
                sortOrder: card.sortOrder!.toString(),
                clientId: this.currentClientId!,
            };
            localStorage.setItem(card.id, JSON.stringify(cardOrder));
        });
        this.dashboardCards.replace(cards);
    };

    getCurrentUser = () => {
        const actions = getActions()!;
        return actions.getCurrentUser();
    };
}
