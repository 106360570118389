import { DashboardCard } from "../types/mixedtypes";
import reqwest from "shared/utils/request";
import { Base64 } from "shared/types/src/basetypes";
import { Result } from "../presenter";
import { getActions } from "../actionregistry";
import { runInAction } from "mobx";

export async function imageRezier(base64: Base64): Promise<Base64> {
    try {
        const result = await reqwest.ajax<Base64>({
            url: config.SERVICE_URL + "/Admin/Dashboard/ResizeImage",
            type: "json",
            // @ts-ignore
            withCredentials: true,
            crossOrigin: true,
            method: "POST",
            data: JSON.stringify(base64),
            contentType: "application/json",
        });
        return result;
    } catch (response: any) {
        if (response.status === 401) {
            const result: Result = await getActions()!.showLoginDialog();
            runInAction(() => {
                if (result == "ok") {
                    return imageRezier(base64);
                }
            });
        }
    }
    return imageRezier(base64);
}
