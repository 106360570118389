import * as React from "react";
import { Toast } from "primereact/toast";

type ToasterType = "info" | "success" | "warn" | "error";

let toaster: Toast;

export function initToaster(g: Toast): void {
    toaster = g;
}

export function showToast(type: ToasterType, message: string, title: string) {
    // This is not a .tsx-file, so I cannot use jsx. I've manually transpiled it. Shouldn't be
    // too hard to follow

    const summary = title;
    const detail = message;
    toaster.show({
        severity: type,
        summary,
        detail,

        sticky: false,
        life: 5000,
    });
}
